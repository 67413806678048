import React, {useState, useLayoutEffect} from 'react';
// import './index.css';
import intro from '../../assets/bg-vid-1.mp4';
import introOne from '../../assets/bg-vid-2.mp4';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function Lighthouse({light}: {light: boolean}) {

  let lightShow = light

  // const [width, height] = useWindowSize();

  // // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  // let vh = height * 0.01;
  // // Then we set the value in the --vh custom property to the root of the document
  // document.documentElement.style.setProperty('--vh', `${vh}px`);

  // let vhWidth = width * 0.01;
  // // Then we set the value in the --vh custom property to the root of the document
  // document.documentElement.style.setProperty('--vhwidth', `${vhWidth}px`);

  return (
    <>
      <div className="scene" style={{display: 'none'}}>
        <div className="background">
          <div className="stars">
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
            <div className="star"></div>
          </div>
          <div className="moon"></div>
          <div className="mountains">
            <div className="mountain"></div>
            <div className="mountain"></div>
            <div className="mountain"></div>
            <div className="mountain"></div>
          </div>
          <div className="sea">
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="waves">
              <div className="boat">
                <div className="base"></div>
                <div className="sail"></div>
                <div className="sail"></div>
                <div className="sail"></div>
              </div>
            </div>
          </div>
        </div>
        { 
          lightShow && 
          
          <div className="lighthouse-group">
            <div className="land"></div>
            <div className="lighthouse-holder">
              <div className="shadow"></div>
              <div className="lighthouse"></div>
              <div className="top"></div>
              <div className="windows">
                <div className="window"></div>
                <div className="window"></div>
                <div className="window"></div>
                <div className="window"></div>
              </div>
              <div className="door">
                <div className="stairs"></div>
              </div>
              <div className="top">
                <div className="light-container">
                  <div className="light"></div>
                </div>
                <div className="rail"></div>
                <div className="middle"></div>
                <div className="roof">
                  <div className="roof-light"></div>
                </div>
                <div className="glow"></div>
              </div>
            </div>
          </div>
        
        }
        
      </div>
      
      <div className=''>
        <video className='videoContainer' width="100%" height="100%" controls={false} muted autoPlay loop>
          <source src={intro} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className='video-block'></div>
      {/* <div className='deep-sea-bg'></div> */}
    </>
  )
}

export default Lighthouse
