import React, { useEffect } from 'react';
import * as $ from "jquery";
import './index.css';
import CButton from '../CButton/CButton';
import { Link, useNavigate } from 'react-router-dom';
import cricketBG from '../../assets/cricket.webp';
import shark from '../../assets/IdleAlphaGIF.gif';

export default function ThreeDCarousel() {
    const navigate = useNavigate();

    function test() {
        let activeIndex = 0
        let limit = 0
        let disabled = false
        let $stage
        let $controls
        let canvas = false

        const SPIN_FORWARD_CLASS = 'js-spin-fwd'
        const SPIN_BACKWARD_CLASS = 'js-spin-bwd'
        const DISABLE_TRANSITIONS_CLASS = 'js-transitions-disabled'
        const SPIN_DUR = 1000

        const appendControls = () => {
            for (let i = 0; i < limit; i++) {
                $('.carousel__control').append(`<a href="#" data-index="${i}"></a>`)
            }
            let height = $('.carousel__control').children().last().outerHeight()

            $('.carousel__control').css('height', (30 + (limit * height)))
            $controls = $('.carousel__control').children()
            $controls.eq(activeIndex).addClass('active')
        }

        const setIndexes = () => {
            $('.spinner').children().each((i, el) => {
                $(el).attr('data-index', i)
                limit++
            })
        }

        const duplicateSpinner = () => {
            const $el = $('.spinner').parent();
            const html = $('.spinner').parent().html()
            console.log($el);
            // console.log(html);
            $el.append(html)
            $('.spinner').last().addClass('spinner--right')
            $('.spinner--right').removeClass('spinner--left')

            $('.spinner--right').on('click', '.carQuizBtn', function () {
                const type = $el.find('.dynamic-type').val();
                localStorage.setItem('quizType', type);
                localStorage.setItem('nextPage', 'silverQuiz');
                navigate('/login')
            });

            $('.spinner--right').on('click', '.carBtn', function () {
                // const type = $el.find('.dynamic-type').val();
                localStorage.setItem('nextPage', 'plans');
                navigate('/login')
            });
        }

        const paintFaces = () => {
            // $('.spinner__face').each((i, el) => {
            //     const $el = $(el)
            //     let color = $(el).attr('data-bg')
            //     $el.children().css('backgroundImage', `url(${getBase64PixelByColor(color)})`)
            // })
        }

        // const getBase64PixelByColor = (hex) => {
        //     if (!canvas) {
        //         canvas = document.createElement('canvas')
        //         canvas.height = 1
        //         canvas.width = 1
        //     }
        //     if (canvas.getContext) {
        //         const ctx = canvas.getContext('2d')
        //         ctx.fillStyle = hex
        //         ctx.fillRect(0, 0, 1, 1)
        //         return canvas.toDataURL()
        //     }
        //     return false
        // }

        const prepareDom = () => {
            setIndexes()
            // // paintFaces()
            duplicateSpinner()
            appendControls()
        }

        const spin = (inc = 1) => {
            if (disabled) return
            if (!inc) return
            activeIndex += inc
            disabled = true

            if (activeIndex >= limit) {
                activeIndex = 0
            }

            if (activeIndex < 0) {
                activeIndex = (limit - 1)
            }

            const $activeEls = $('.spinner__face.js-active')
            const $nextEls = $(`.spinner__face[data-index=${activeIndex}]`)
            $nextEls.addClass('js-next')

            if (inc > 0) {
                $stage.addClass(SPIN_FORWARD_CLASS)
            } else {
                $stage.addClass(SPIN_BACKWARD_CLASS)
            }

            $controls.removeClass('active')
            $controls.eq(activeIndex).addClass('active')

            setTimeout(() => {
                spinCallback(inc)
            }, SPIN_DUR, inc)
        }

        const spinCallback = (inc) => {

            $('.js-active').removeClass('js-active')
            $('.js-next').removeClass('js-next').addClass('js-active')
            $stage
                .addClass(DISABLE_TRANSITIONS_CLASS)
                .removeClass(SPIN_FORWARD_CLASS)
                .removeClass(SPIN_BACKWARD_CLASS)

            $('.js-active').each((i, el) => {
                const $el = $(el)
                $el.prependTo($el.parent())
            })
            setTimeout(() => {
                $stage.removeClass(DISABLE_TRANSITIONS_CLASS)
                disabled = false
            }, 100)

        }

        const attachListeners = () => {

            document.onkeyup = (e) => {
                switch (e.keyCode) {
                    case 38:
                        spin(-1)
                        break
                    case 40:
                        spin(1)
                        break
                }
            }

            $controls.on('click', (e) => {
                e.preventDefault()
                if (disabled) return
                const $el = $(e.target)
                const toIndex = parseInt($el.attr('data-index'), 10)
                spin(toIndex - activeIndex)

            })
        }

        const assignEls = () => {
            $stage = $('.carousel__stage')
        }

        const init = () => {
            assignEls()
            prepareDom()
            attachListeners()
        }


        $(() => {
            init();
        });
    }

    // document.getElementById('challenge')?.addEventListener("click", handleClick);
    // console.log(document.getElementById('challenge'));

    // const handleClick = () => {
    //     alert('test');
    // }

    useEffect(() => {
        test();
        // document.getElementById("challenge").addEventListener("click", () => {
        //     alert('add')
        // }) 
        // document.getElementById('challenge')?.addEventListener("click", function manish () {
        //     alert('red')
        // });
        // console.log(document.getElementById('challenge'));
    })

    return (
        <>
            <div className="carousel">
                <div className="carousel__control">
                </div>
                <div className="carousel__stage">
                    <div className="spinner spinner--left">
                        <div className="spinner__face js-active" data-bg="#27323c">
                            <input className='dynamic-type' type='hidden' value="cricket" />
                            <div className="content" data-type="iceland" style={{ backgroundColor: '#7f8b5f' }}>
                                <Link to="/cricket" className="content__left">
                                    <h1>CRICKET<br /><span>Mr.Fin</span></h1>
                                </Link>
                                <div className="content__right">
                                    <div className="content__main">
                                        <p>
                                            <b style={{fontSize: "24px"}}>Cricket</b><br/>
                                            <b style={{fontSize: "24px"}}>Quiz Date: 27th Oct 2024</b>
                                            <br/>
                                            <br/>
                                            “Did You Know?
                                            Cricket is the second most popular sport after soccer/football with an estimated fan base of 2.5 billion people. If you are one such fan then challenge yourself among your peers and win assured prizes every week.” </p>
                                        <div className='carBtn-grp'>
                                            <button className='carBtn pulse-btn-car'>Challenge Now</button>
                                            <button className='carQuizBtn pulse-btn-car'>Practice Quiz</button>
                                        </div>
                                        {/* <CButton type="cricket"/> */}
                                    </div>
                                    <h3 className="content__index">01</h3>
                                </div>
                            </div>
                        </div>
                        
                        <div className="spinner__face" data-bg="#2b2533">
                            <input className='dynamic-type' type='hidden' value="travel" />
                            <div className="content" data-type="usa" style={{ backgroundColor: "#7b7569" }}>
                                <Link to="/travel" className="content__left">
                                    <h1>Travel<br /><span>Mr. Fin</span></h1>
                                </Link>
                                <div className="content__right">
                                    <div className="content__main">
                                        <p>
                                            <b style={{fontSize: "24px"}}>Travel</b><br/>
                                            <b style={{fontSize: "24px"}}>Quiz Date: 27th Oct 2024</b>
                                            <br/>
                                            <br/>
                                            “Today the longest commercial flight is the Singapore Airlines Singapore to New York route, with an average journey time of 17 hours and 50 minutes. The entire world has shrunk for travellers. Now we are here to make such travels even more sweeter by bestowing you with gifts worth 2.4 Lakhs every months. Get your travel vouchers with us NOW!!!!” </p>
                                        <div className='carBtn-grp'>
                                            <button className='carBtn pulse-btn-car'>Challenge Now</button>
                                            <button className='carQuizBtn pulse-btn-car'>Practice Quiz</button>
                                        </div>
                                    </div>
                                    <h3 className="content__index">03</h3>
                                </div>
                            </div>
                        </div>
                        <div className="spinner__face" data-bg="#312f2d">
                            <input className='dynamic-type' type='hidden' value="food" />
                            <div className="content" data-type="peru" style={{ backgroundColor: "#65837c" }}>
                                <Link to="/food" className="content__left">
                                    <h1>FOOD<br /><span>Mr.Fin</span></h1>
                                </Link>
                                <div className="content__right">
                                    <div className="content__main">
                                        <p>
                                            <b style={{fontSize: "24px"}}>Food</b><br/>
                                            <b style={{fontSize: "24px"}}>Quiz Date: 27th Oct 2024</b>
                                            <br/>
                                            <br/>
                                            “There's more water in cucumber than watermelon. If you are the kinda of person who knows even such obscure facts, then we have fun filled quiz which will win you prizes that will reward you for just being a foodie.”</p>
                                        <div className='carBtn-grp'>
                                            <button className='carBtn pulse-btn-car'>Challenge Now</button>
                                            <button className='carQuizBtn pulse-btn-car'>Practice Quiz</button>
                                        </div>
                                        {/* <Button type='submit' variant='contained' style={{marginTop: '20px'}} onClick={() => handleClick('food')}>Challenge Now</Button> */}
                                    </div>
                                    <h3 className="content__index">04</h3>
                                </div>
                            </div>
                        </div>
                        <div className="spinner__face" data-bg="#312f2d">
                            <input className='dynamic-type' type='hidden' value="mythology" />
                            <div className="content" data-type="canada" style={{ background: "#111" }}>
                                <Link to="/mythology" className="content__left">
                                    <h1>MYTHOLOGY<br /><span>Mr.Fin</span></h1>
                                </Link>
                                <div className="content__right">
                                    <div className="content__main">
                                        <p>
                                            <b style={{fontSize: "24px"}}>Mythology</b><br/>
                                            <b style={{fontSize: "24px"}}>Quiz Date: 3rd Nov 2024</b>
                                            <br/>
                                            <br/>
                                            “Ramayana and Bhagwat Gita are treated to be most virtuous and sacred in india. The more you learn about Indian mythology, the more you realize its richness in terms of knowledge and preaching. If you are knowledgable in this rich tapestry of mythology, give our quiz a whirl and win prizes every month!!! ”</p>
                                        <div className='carBtn-grp'>
                                            <button className='carBtn pulse-btn-car'>Challenge Now</button>
                                            <button className='carQuizBtn pulse-btn-car'>Practice Quiz</button>
                                        </div>
                                        {/* <Button type='submit' variant='contained' style={{marginTop: '20px'}} onClick={() => handleClick('food')}>Challenge Now</Button> */}
                                    </div>
                                    <h3 className="content__index">04</h3>
                                </div>
                            </div>
                        </div>
                        <div className="spinner__face" data-bg="#312f2d">
                            <input className='dynamic-type' type='hidden' value="finance" />
                            <div className="content" data-type="india" style={{ background: "#111" }}>
                                <Link to="/finance" className="content__left">
                                    <h1>Finance<br /><span>Mr.Fin</span></h1>
                                </Link>
                                <div className="content__right">
                                    <div className="content__main">
                                        <p>
                                            <b style={{fontSize: "24px"}}>Finance</b><br/>
                                            <b style={{fontSize: "24px"}}>Quiz Date: 3rd Nov 2024</b>
                                            <br/>
                                            <br/>
                                            “An investment in knowledge pays the best interest. This is our founding ethos. If you know how to make money work for you via your know-how of the system, then let us give you a hand. Challenge our quiz to win prizes worth 2.4 Lkhs. If you don't trust in your knowledge, Who will?”</p>
                                        <div className='carBtn-grp'>
                                            <button className='carBtn pulse-btn-car'>Challenge Now</button>
                                            <button className='carQuizBtn pulse-btn-car'>Practice Quiz</button>
                                        </div>
                                        {/* <Button type='submit' variant='contained' style={{marginTop: '20px'}} onClick={() => handleClick('food')}>Challenge Now</Button> */}
                                    </div>
                                    <h3 className="content__index">04</h3>
                                </div>
                            </div>
                        </div>
                        <div className="spinner__face" data-bg="#19304a">
                            <input className='dynamic-type' type='hidden' value="history" />
                            <div className="content" data-type="china" style={{ backgroundColor: "#bfa77e" }}>
                                <Link to="/history" className="content__left">
                                    <h1>HISTORY<br /><span>Mr.Fin</span></h1>
                                </Link>
                                <div className="content__right">
                                    <div className="content__main">
                                        <p>
                                            <b style={{fontSize: "24px"}}>History</b><br/>
                                            <b style={{fontSize: "24px"}}>Quiz Date: 3rd Nov 2024</b>
                                            <br/>
                                            <br/>
                                            Did You know?
                                            India never invaded any country in her last 100000 years of history. Indian history is both ancient and fascinating. If you're fan of such things, we got you covered. Give our history Quizzes a whirl and win amazing prizes every month.</p>
                                        <div className='carBtn-grp'>
                                            <button className='carBtn pulse-btn-car'>Challenge Now</button>
                                            <button className='carQuizBtn pulse-btn-car'>Practice Quiz</button>
                                        </div>
                                        {/* <Button type='submit' style={{marginTop: '20px'}} variant='contained' onClick={() => handleClick('history')}>Challenge Now</Button> */}
                                    </div>
                                    <h3 className="content__index">02</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ height: 0, width: 0, overflow: "hidden" }}>
                <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/215059/peru.jpg" />
                <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/215059/canada.jpg" />
                <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/215059/china.jpg" />
                <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/215059/usa.jpg" />
                <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/215059/iceland.jpg" />
            </div>
        </>
    )
}
