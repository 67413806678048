import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import travelLogo from '../assets/mascot/king.png';

const HistoryPage = () => {
  const Navigate = useNavigate();

  function carQuizBtn () {
    localStorage.setItem('quizType', 'history');
    localStorage.setItem('nextPage', 'silverQuiz');
    Navigate('/login');
  }

  function carBtn () {
    localStorage.setItem('quizType', 'history');
    localStorage.setItem('nextPage', 'plans');
    Navigate('/login');
  }
  return (
    <div className='quizPanel blogPanel'>
      <div className='quizPanel-left'>
        <div className='quizPanel-section' style={{ width: "100%", height: "100%" }}>
          <div className='quizPanel-logo' style={{ marginLeft: 0 }}>
            <img src={logo} alt="logo" />
          </div>
          <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", flex: 1 }}>
            <img src={travelLogo} style={{ height: "350px" }} />
          </div>
          <div>
            <h3 style={{textAlign: "center", marginTop: "20px"}}>Quiz Date: 3rd Nov 2024</h3>
            <div className='carBtn-grp'  style={{marginBottom: "20px"}}>
              <button className='carBtn pulse-btn-car' onClick={carBtn}>Challenge Now</button>
              <button className='carQuizBtn pulse-btn-car' onClick={carQuizBtn}>Practice Quiz</button>
            </div>
          </div>
        </div>
      </div>
      <div className='quizPanel-right'>
        <div>
          <h2 style={{ marginBottom: '20px', textAlign: "center", textDecoration: "underline" }}>History</h2>
          <h3 style={{ marginBottom: '10px' }}>Here are some engaging topics you can practice and prepare for the History quiz</h3>
          <ol>
            <li>
              <b>Ancient Civilizations:</b>
              <ul>
                <li>
                  Indus Valley Civilization: Cities, architecture, and artifacts.
                </li>
                <li>
                  Vedic Period: Society, culture, and major texts (Vedas, Upanishads).
                </li>
              </ul>
            </li>

            <li>
              <b>Dynasties and Kingdoms:</b>
              <ul>
                <li>
                  Maurya Empire: Key figures like Chandragupta Maurya, Ashoka, and their achievements.
                </li>
                <li>
                  Gupta Empire: Golden Age of India, advancements in science, art, and literature.
                </li>
                <li>
                  Chola Dynasty: Tamil culture, maritime power, and temple architecture.
                </li>
              </ul>
            </li>

            <li>
              <b>Invasions and Empires:</b>
              <ul>
                <li>
                  Invasion of Alexander the Great and its impact.
                </li>
                <li>
                  The Delhi Sultanate: Important rulers like Qutb-ud-din Aibak, Alauddin Khilji, and the establishment of Muslim rule.
                </li>
                <li>
                  The Mughal Empire: Major emperors, cultural and architectural contributions (Taj Mahal, Red Fort, etc.).
                </li>
              </ul>
            </li>

            <li>
              <b>Freedom Struggle:</b>
              <ul>
                <li>
                  Revolt of 1857: Causes, key leaders, and outcomes.
                </li>
                <li>
                  Non-cooperation and Civil Disobedience Movements: Gandhi’s role, Salt March.
                </li>
                <li>

                  Indian National Congress: Role in independence, major leaders (Nehru, Patel, etc.).
                </li>
                <li>
                  Partition of India: Causes, consequences, and key events during the partition.
                </li>
              </ul>
            </li>

            <li>
              <b>British Colonial Rule:</b>
              <ul>
                <li>
                  East India Company and the establishment of British dominance.
                </li>
                <li>
                  Reforms and policies: Introduction of railways, education, and legal systems.
                </li>
                <li>
                  Major movements against British rule: Swadeshi Movement, Quit India Movement.
                </li>
              </ul>
            </li>

            <li>
              <b>Post-Independence India:</b>
              <ul>
                <li>
                  Framing of the Constitution and its key features.
                </li>
                <li>
                  First general elections and Nehruvian policies.
                </li>
                <li>
                  Green Revolution and its impact on Indian agriculture.
                </li>
                <li>
                  Wars with Pakistan and China: 1962, 1965, 1971 conflicts.
                </li>
              </ul>
            </li>

            <li>
              <b>Cultural Heritage:</b>
              <ul>
                <li>
                  Indian art, architecture, and literature through different periods.
                </li>
                <li>
                  Famous historical monuments and their significance (Sanchi Stupa, Konark Sun Temple, etc.).
                </li>

              </ul>
            </li>

            <li>
              <b>Social Reform Movements:</b>
              <ul>
                <li>
                  Raja Ram Mohan Roy and abolition of Sati.
                </li>
                <li>
                  Jyotiba Phule and the Dalit movement.
                </li>
                <li>
                  B.R. Ambedkar and the Dalit struggle for equality.
                </li>
                <li>
                  Women's rights movements in colonial and post-colonial India.
                </li>
              </ul>
            </li>

            <li>
              <b>Regional Histories:</b>
              <ul>
                <li>
                  History of Marathas under Shivaji.
                </li>
                <li>
                  History of Vijayanagara Empire.
                </li>
                <li>
                  History of Bengal under various rulers.
                </li>
              </ul>
            </li>

          </ol>
        </div>
      </div>
      <Link to="/home" className='close'>
        X
      </Link>
    </div>
  )
}

export default HistoryPage

