import { useEffect, useState } from 'react'
import gsap from 'gsap';
import './index.css';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import Mountaina02 from '../../assets/parallex/bg/left mountain.png';
import Mountaina01 from '../../assets/parallex/bg/rright mountain.png';
import land from '../../assets/parallex/bg/base-layer 2.png';
import logo from '../../assets/logo.png';
import starFish from '../../assets/parallex/bg/starfish.png';
import fish1 from '../../assets/parallex/bg/fish2.png';
import fish2 from '../../assets/parallex/bg/fish1.png';
import nemo from '../../assets/parallex/bg/nemo.png';
import corals from '../../assets/parallex/bg/corals.png';
import corals1 from '../../assets/parallex/bg/coral2.png';
import plant2 from '../../assets/parallex/bg/plant2.png';
import greenCoral from '../../assets/parallex/bg/green-coral.png';
import shell from '../../assets/parallex/bg/shell.png';
import shell2 from '../../assets/parallex/bg/shell2.png';
import plant3 from '../../assets/parallex/bg/plant3.png';
import backbg from '../../assets/parallex/bg/bg.png';
import Mascot from '../Mascot/Mascot';
import FAQ from '../Faq/Faq';
import ThreeDCarousel from '../ThreeDCarousel/ThreeDCarousel';
import Rules from '../Rules/Rules';

gsap.registerPlugin(ScrollTrigger);

function Parallex() {
  const [faqs, setFaqs] = useState([
    {
      question: "How is a winner determined?",
      answer:
        "Its based on percentile system. The one who has answered the most amnount of questions in the least amount of time gets to be the winner. ",
      open: true
    },
    {
      question: "What if two people answer the same amount of questions in the same time?",
      answer: "We rack upto microseconds of the answer submission. The one with the least amount of time with the same score will win the prize. If even the micro seconds are the same then both of them are eleigible for the prize.",
      open: false
    },
    {
      question:
        "When do the prizes get announced?",
      answer: "Once the Quiz is conducted the results are tallied and announced in 24 hours.",
      open: false
    },
    {
      question:
        "How often are the quizzes conducted?",
      answer: "Silver tier quizzes are conducted every weeek, while the gold tier quizzes are conducted once every two weeks. The diamond tier quizzes are conducted every three months.",
      open: false
    },
    {
      question:
        "When can i attempt the quiz?",
      answer: "The date will be provided for every quiz. You will have a window of 24 hours.",
      open: false
    },
    {
      question:
        "How can i claim the prizes?",
      answer: "We will contact you with the number and mail id thats been used for registration and the prizes will be delivered to you in 7 working days.",
      open: false
    },
    {
      question: "Can you use the coins awarded to you to participate in any quiz?",
      answer: "Unfortunately not. Any and all coins that you received can only be used to upgrade you to challenge our diamond quiz. Once you reach the required amount of coins, you will be eligible to participate in a diamond quiz.",
      open: false
    }
  ]);


  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }
        return faq;
      })
    );
  };
  useEffect(() => {
    let ctx = gsap.context(() => {
      var tlPage1 = gsap.timeline({
        defaults: { duration: 1, stagger: 2 },

        scrollTrigger: {
          trigger: "#page1",
          start: "top top",
          end: "1500 bottom",
          scrub: true,
          pin: true,

          // markers: true,
        },
      })
      tlPage1.to(".page1-content", {
        opacity: 0,
        scale: 0,
        duration: 5
      },
        1
      )
      let tl = gsap.timeline({
        defaults: { duration: 1, stagger: 2 },
        scrollTrigger: {
          trigger: ".page2",
          pin: true,
          start: "top top",
          end: "4000 bottom",
          scrub: true,
          // snap: {
          //   snapTo: "labels",
          //   duration: { min: 0.2, max: 3 },
          //   delay: 0.2,
          //   ease: "power3"
          // }
        }
      });
      // // add animations and labels to the timeline
      gsap.set('.title, .title2, .mascotPanel, .faqPanel', { display: 'none' })
      gsap.set(".backbg, .land, .title, .title2, .mountain-1, .mountain-2, .fishImage, .fishImage7, .fishImage10, .star-fish, .corals, .corals1, .greencoral, .shell, .shell2, .plant2, .plant3, .mascotPanel, .faqPanel", { autoAlpha: 0, });
      tl.addLabel('textout')
        // .to('.page2-container', {duration: 30, autoAlpha: 0, display: 'none'})
        // .addLabel("manish")
        .to('.land', { duration: 3, autoAlpha: 1 })
        .addLabel("textout")
        .duration(10)
        .to('.backbg', { duration: 3, autoAlpha: 1 })
        .addLabel("textout")
        .to('.title', { duration: 3, autoAlpha: 1, display: 'flex' })
        .addLabel("textout")
        .to('.mountain-1', { duration: 3, autoAlpha: 1 })
        .addLabel("textout")
        .to('.title', { duration: 3, autoAlpha: 0, display: 'none' })
        .addLabel("textout")
        .to('.title2', { duration: 3, autoAlpha: 1, display: 'flex' })
        .addLabel("textout")
        .to('.mountain-2', { duration: 15, autoAlpha: 1 })
        .addLabel("textout")
        .to('.title2', { duration: 15, autoAlpha: 0, display: 'none' })
        // .addLabel("textout")
        // .to('.corals', {duration: 15, autoAlpha: 1})
        // .addLabel("textout")
        // .to('.corals1', {duration: 15, autoAlpha: 1})
        // .addLabel("textout")
        // .to('.plant2', {duration: 15, autoAlpha: 1})
        // .addLabel("textout")
        // .to('.plant3', {duration: 15, autoAlpha: 1})
        // .addLabel("textout")
        // .to('.greencoral', {duration: 15, autoAlpha: 1})
        // .addLabel("textout")
        // .to('.shell', {duration: 15, autoAlpha: 1})
        // .addLabel("textout")
        // .to('.shell2', {duration: 15, autoAlpha: 1})
        .addLabel("textout")
        .to('.star-fish', { duration: 15, autoAlpha: 1 })
        .addLabel("textout")
        .to('.fishImage', { duration: 15, autoAlpha: 1 })
        .addLabel("textout")
        .to('.fishImage7', { duration: 15, autoAlpha: 1 })
        .addLabel("textout")
        .to('.fishImage10', { duration: 15, autoAlpha: 1 })
      // .addLabel("textout")
      // .to('.mascotPanel', {duration: 15, autoAlpha: 1, display: "block"})
      // .addLabel("textout")
      // .to('.mascotPanel', {duration: 3, autoAlpha: 1, display: "none"})
      // .addLabel("textout")
      // .to('.faqPanel', {duration: 15, autoAlpha: 1, display: "block"})
      // .addLabel("textout")
      // .to('.faqPanel', {duration: 3, autoAlpha: 1, display: "none"})

    })
  }, [])
  return (
    <div className=' parallexPage'>
      {/*  */}
      <div className="page page1" id="page1">
        <div className='page1-content'>
          <h1 className='page-content-title'>Welcome to</h1>
          <div class="Iam">
            <b>
              <div class="innerIam">
                FUN<br />
                EDUCATION<br />
                ENTERTAINMENT<br />
                COMPETITION<br />
                PRIZES WORTH CRORES
              </div>
            </b>

          </div>
          <img className='page-logo' src={logo} alt="logo" />
          <h3>We are an Edutainment platform</h3>

          {/* <h1>
            <span>W</span>
            <span>e</span>
            <span>l</span>
            <span>c</span>
            <span>o</span>
            <span>m</span>
            <span>e</span>
            <br />
            <span>S</span>
            <span>h</span>
            <span>a</span>
            <span>r</span>
            <span>k</span>
            <span>s</span>
          </h1> */}

        </div>
        {/* <div className='mountains'></div> */}
        <div class="ocean">
          <div class="wave"></div>
          <div class="wave wave2"></div>
        </div>
      </div>
      <div className="page page2" id="page2">
      {/* <section class="page2-container">
  <h1 class="">
    <span>Hi, nice</span>
    <span>to see</span>
    <span>you here</span>
  </h1>
  
  <h2 class="">
    <span>This is</span>
    <span>a long</span>
    <span>sub title</span>
    
    
  </h2>
</section> */}
        <h1 className='title'>Congrats on getting your feet wet in the world of scholar sharks. This is a perfect place for you to start. </h1>
        <h1 className='title2'>Here you will find silver fin sharks. There are weekly quizzes for all the sharks that will give out prizes worth 1 lakh.</h1>



        <img src={land} alt="Fish" className='land' />
        <img src={backbg} alt="" className='backbg' />
        <img src={Mountaina01} alt="Fish" className='mountain-1' />
        <img src={Mountaina02} alt="Fish" className='mountain-2' />
        <img src={starFish} alt="Fish" className='star-fish' />
        <img src={nemo} alt="Fish" className='fishImage' />
        <img src={fish1} alt="Fish" className='fishImage7' />
        <img src={fish2} alt="Fish" className='fishImage10' />
        {/* <img src={plant2} alt="Fish" className='plant2' />
        <img src={plant3} alt="Fish" className='plant3' />
        <img src={corals} alt="corals" className='corals' />
        <img src={corals1} alt="corals" className='corals1' />
        <img src={greenCoral} alt="Fish" className='greencoral' />
        <img src={shell} alt="corals" className='shell' />
        <img src={shell2} alt="corals" className='shell2' /> */}

      </div>
      <div className='page page3' id="page3">
        {/* <h1 className='section-title'>Mr. Fin with HIS interest....</h1> */}
        <ThreeDCarousel />
      </div>
      <div className='page page4' id="page4">
        <h1 className='section-title'>Rules of Engagement</h1>
        <Rules />
      </div>
      <div className='page page5' id="page5">
        <h1 className='section-title'>FAQ's</h1>
        <div className="faqs">
          {faqs.map((faq, index) => (
            <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
          ))}
        </div>
      </div>

    </div>
  )
}
export default Parallex