import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import travelLogo from '../assets/mascot/foodie.png';

const FoodPage = () => {
  const Navigate = useNavigate();

  function carQuizBtn () {
    localStorage.setItem('quizType', 'food');
    localStorage.setItem('nextPage', 'silverQuiz');
    Navigate('/login');
  }

  function carBtn () {
    localStorage.setItem('quizType', 'food');
    localStorage.setItem('nextPage', 'plans');
    Navigate('/login');
  }

  return (
    <div className='quizPanel blogPanel'>
      <div className='quizPanel-left'>
        <div className='quizPanel-section' style={{width: "100%", height: "100%"}}>
          <div className='quizPanel-logo' style={{marginLeft: 0}}>
            <img src={logo} alt="logo" />
          </div>
          <div style={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center", flex: 1}}>
            <img src={travelLogo} style={{height: "350px"}} />
          </div>
          <div>
            <h3 style={{textAlign: "center", marginTop: "20px"}}>Quiz Date: 27th Oct 2024</h3>
            <div className='carBtn-grp' style={{marginBottom: "20px"}}>
              <button className='carBtn pulse-btn-car' onClick={carBtn}>Challenge Now</button>
              <button className='carQuizBtn pulse-btn-car' onClick={carQuizBtn}>Practice Quiz</button>
            </div>
          </div>
        </div>
      </div>
      <div className='quizPanel-right'>
        <div>
          <h2 style={{marginBottom: '20px', textAlign: "center", textDecoration: "underline"}}>Food</h2>
          <h3 style={{marginBottom: '10px'}}>Here are some engaging topics you can practice and prepare for the food quiz</h3>
          <ol>
            <li>
              <b>Regional Cuisines:</b>Questions about popular dishes from different states (e.g., Rogan Josh from Kashmir, Dhokla from Gujarat, Puttu from Kerala).
            </li>
            <li>
              <b>Spices and Ingredients:</b>The role of spices like turmeric, cardamom, cumin, and popular ingredients like lentils, rice, or coconut in Indian cooking.
            </li>
            <li>
              <b>Festive Foods: </b>Special dishes prepared during Indian festivals (e.g., Modak during Ganesh Chaturthi, Pongal during Pongal festival).
            </li>
            <li>
              <b>Street Food:</b> Famous street foods across India, like Pani Puri, Chole Bhature, and Vada Pav.
            </li>
            <li>
              <b>Traditional Desserts:</b> Knowledge of Indian sweets such as Jalebi, Kheer, Rasgulla, and Mysore Pak.y those who led their teams to victories in major tournaments.
            </li>
            <li>
              <b>Food Origins:</b> Origins of famous dishes, both native and those influenced by invaders or traders (e.g., Biryani, Samosa).
            </li>
            <li>
              <b>Unique Dishes:</b> Unusual dishes from different parts of India like Bhutte Ka Kees from Madhya Pradesh or Bamboo Shoot dishes from the Northeast.
            </li>
            <li>
              <b>Famous Chefs and Cookbooks:</b> Questions on celebrity chefs like Sanjeev Kapoor or Tarla Dalal and their contributions to Indian cuisine.
            </li>
            <li>
              <b>Food in Ayurveda:</b> How Indian food is influenced by Ayurveda, such as the importance of balancing tastes (sweet, salty, bitter, etc.) in meals.
            </li>
            <li>
              <b>Cooking Techniques:</b> Popular techniques used in Indian cooking, such as tandoor, dum, and tadka.
            </li>
            <li>
              <b>Indian Beverages:</b> Traditional Indian drinks like Masala Chai, Lassi, Thandai, and Sharbat.
            </li>
            <li>
              <b>Regional Breakfasts:</b> Specific breakfast dishes from different states like Idli-Sambar from South India, Poha from Maharashtra, or Parathas from North India.
            </li>
            <li>
              <b>Historical Dishes:</b> Foods mentioned in ancient texts or prepared during royal feasts in India.
            </li>
            <li>
              <b>Fusion Foods:</b> Indian dishes with a twist, like Indo-Chinese cuisine (Manchurian, Hakka Noodles) or modern fusion desserts.
            </li>
			<li>
              <b>Pickles and Preserves:</b> Different types of pickles (Achar) found across India, made from mango, lime, chili, and more.
            </li>
          </ol>
        </div>
      </div>
      <Link to="/home" className='close'>
        X
      </Link>
    </div>
  )
}

export default FoodPage

