import { Button } from '@mui/material';
import React, {useState} from 'react';
import './index.css';
import next from '../../assets/next.svg';
import prev from '../../assets/prev.svg';

const cardInfo =  [
        {
                "title": "TIMELINE FOR QUIZZES",
                "content": "Every quiz has a set amount of time under which the quiz needs to be finished. No extensions no matter what circumstance the user may face"
        },
  {             
          "title": "ATTEMPTS",
          "content": "You can use our practice sessions any number of times you want, but you can attempt the main quizzes only once during their cycle."
        },
  {
          "title": "DIAMOND PRIVILEGES",
          "content": "Under the diamond fin, you can participate in the quarterly quiz once and bag a Mercedes or prizes worth up to 15 CR and still stand a chance to participate in one sliver and one gold quiz. "
  },
  {
          "title": "GOLD PRIVILEGES",
          "content": "Under the gold fin, you can participate in monthly quizzes and bag prizes worth 2.5 lakhs monthly. More than 50 people will claim prizes each month. If you take the quiz twice you can upgrade to the diamond quiz."
  },
  {
          "title": "SILVER PRIVILEGES",
          "content": "Attempting a Silver quiz four times within one quarter will automatically get you an attempt at the Diamond quiz, which can be attempted within the current quarter."
  }
  
]


export default function Carousel({children}: any) {
  const MAX_VISIBILITY = 3;
  const CARDS = 5;
  const [active, setActive] = useState(0);
  const count = 5;

  return (
    <div className='rulesCarousel'>
    <div className='carousel'>
      {active > 0 && <button className='carNav left' onClick={() => setActive(i => i - 1)}><img src={prev} alt="prev"/></button>}
      {cardInfo.map((card, i) => (
        <div className='card-container' style={{
            '--active': i === active ? 1 : 0,
            '--offset': (active - i) / 3,
            '--direction': Math.sign(active - i),
            '--abs-offset': Math.abs(active - i) / 3,
            'pointer-events': active === i ? 'auto' : 'none',
            'opacity': Math.abs(active - i) >= MAX_VISIBILITY ? '0' : '1',
            'display': Math.abs(active - i) > MAX_VISIBILITY ? 'none' : 'block',
          } as React.CSSProperties}>
          <div className='card'>
            <h2>{card.title}</h2>
            <p>{card.content}</p>
          </div>
        </div>
      ))}
      {active < count - 1 && <button className='carNav right' onClick={() => setActive(i => i + 1)}><img src={next} alt="next"/></button>}
    </div>
    </div>
  );
}

// const Carousel = ({children}) => {
//     const [active, setActive] = useState(2);
//     const count = React.Children.count(children);
    
//     return (
//       <div className='carousel'>
//         {active > 0 && <button className='nav left' onClick={() => setActive(i => i - 1)}><TiChevronLeftOutline/></button>}
//         {React.Children.map(children, (child, i) => (
//           <div className='card-container' style={{
//               '--active': i === active ? 1 : 0,
//               '--offset': (active - i) / 3,
//               '--direction': Math.sign(active - i),
//               '--abs-offset': Math.abs(active - i) / 3,
//               'pointer-events': active === i ? 'auto' : 'none',
//               'opacity': Math.abs(active - i) >= MAX_VISIBILITY ? '0' : '1',
//               'display': Math.abs(active - i) > MAX_VISIBILITY ? 'none' : 'block',
//             }}>
//             {child}
//           </div>
//         ))}
//         {active < count - 1 && <button className='nav right' onClick={() => setActive(i => i + 1)}><TiChevronRightOutline/></button>}
//       </div>
//     );
//   };
  
//   const App = () => (
//     <div className='app'>
//       <Carousel>
//         {[...new Array(CARDS)].map((_, i) => (
//           <Card title={'Card ' + (i + 1)} content='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'/>
//         ))}
//       </Carousel>
//     </div>
//   );
