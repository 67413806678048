import { Route, Routes, useLocation } from 'react-router-dom';
import Videopage from './Components/Videopage/Videopage';
import Quizpage from './Components/Quizpage/Quizpage';
import RegisterPage from './Pages/RegisterPage';
import LanguagePage from './Pages/LanguagePage';
import LoginPage from './Pages/LoginPage';
import LeaderboardPage from './Pages/LeaderboardPage';
import DashboardPage from './Pages/DashboardPage';
import SilverQuizPage from './Components/SilverQuizPage/SilverQuizPage';
import { AcceleratedAnimation, AnimatePresence } from 'framer-motion';
import HomeDesktop from './Pages/HomeDesktop';
import Home from './Pages/Home';
import ParallexMobile from './Components/ParallexMobile/ParallexMobile';
import PlanPagenew from './Pages/PlanPagenew';
import VerifyPage from './Pages/VerifyPage';
import CricketPage from './Pages/Cricket';
import TravelPage from './Pages/Travel';
import MythologyPage from './Pages/Mythology';
import FoodPage from './Pages/Food';
import HistoryPage from './Pages/History';
import FinancePage from './Pages/Finance';
import DiamondPage from './Pages/DIamond';


const AnimatedRoutes = () => {

    const location = useLocation();
    

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                {/* <Route path="/" element={<Language />}> */}
                <Route index element={<Videopage />} />
                <Route path="plans" element={<PlanPagenew hideHeader={false}/>} />
                <Route path="quiz" element={<Quizpage />} />
                <Route path="register" element={<RegisterPage />} />
                <Route path="language" element={<LanguagePage />} />
                <Route path="login" element={<LoginPage />} />
                <Route path="leaderboard" element={<LeaderboardPage />} />
                <Route path="dashboard" element={<DashboardPage />} />
                <Route path="home" element={<HomeDesktop />} />
                <Route path="home1" element={<ParallexMobile />} />
                <Route path="silverQuiz" element={<SilverQuizPage />} />
                <Route path="test1" element={<Home />} />
                <Route path="verify" element={<VerifyPage />} />
                <Route path="cricket" element={<CricketPage />} />
                <Route path="food" element={<FoodPage />} />
                <Route path="travel" element={<TravelPage />} />
                <Route path="mythology" element={<MythologyPage />} />
                <Route path="history" element={<HistoryPage />} />
                <Route path="finance" element={<FinancePage />} />
                <Route path="diamond" element={<DiamondPage />} />
                <Route path="*" element={<Home />} /> 
                {/* </Route> */}
            </Routes>
        </AnimatePresence>
    )

    
}

export default AnimatedRoutes;
