import { useEffect, useRef } from 'react';
import './index.css';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import intro from '../../assets/bg-text-vid.mp4';
import introMob from '../../assets/bg-video-mob.mp4';
import { isMobile } from 'react-device-detect';

function Videopage() {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  

  useEffect(() => {
    const video : HTMLVideoElement | null = document.querySelector('video');
    // let menuDisplay : any = document.querySelector<HTMLElement>('.rotate-screen')?.style.display;
    //@ts-ignore
    // videoRef.current?.playsInline = true;
    // console.log(video);
    video?.addEventListener('ended', (event) => {
      // console.log('ended');
      // let man : any = document.getElementById('screen');
      // man.style.display='block';
        //you can also do things with 'event' obj 
        navigate('/language');
    });
  }, []) 

  // useEffect(() => {
  //     document.getElementById('text')?.classList.add('animate');

  //     setTimeout(() => {
  //       navigate('/language');
  //     }, 18000)
  // }, [])

  return (
    <>
    {/* <div className='intro-bg'>

    </div> */}
      <video width="100%" ref={videoRef} height="100%" playsInline controls={false} autoPlay muted>
        <source src={isMobile ? introMob : intro} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Button variant='contained' className='skip-btn-vid' onClick={() => navigate('/language')}>Skip</Button>
      {/* <Button type='submit' variant='contained' style={{marginTop: '20px'}} onClick={() =>  navigate('/register')}>Skip Intro</Button> */}
    </>
  )
}

export default Videopage
