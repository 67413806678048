import Language from "../Components/Language/Language";
import Layout from "./Layout";

function LanguagePage() {
    return (
        // <Layout lightBg={true}>
        <div style={{display: 'flex', height: '100%'}}>
            <Language />
        </div>
            
        // </Layout>
        
    )
}

export default LanguagePage;