import { useEffect, useState } from 'react'
import gsap from 'gsap';
import './index.css';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import shark from '../../assets/parallex/shark 2.png';
import driver from '../../assets/parallex/Scuba-diver01.png';
import coral from '../../assets/parallex/shell-sliver.png';
import coralClose from '../../assets/parallex/shell-sliver-01.png';
import coralGolden from '../../assets/parallex/shell-gold-01.png';
import logo from '../../assets/logo.png';
import FAQ from '../Faq/Faq';
import Tressure from '../Tressure/Tressure';
import Fish from '../Fish/Fish';
import Mascot from '../Mascot/Mascot';
import InfiniteCarousal from '../InfiniteCarousal/InfiniteCarousal';
import FishImage from '../../assets/parallex/06.png';
import FishImage7 from '../../assets/parallex/07.png';
import FishImage10 from '../../assets/parallex/10.png';
import Tortoise from '../../assets/parallex/20.png';
import diamondShell from '../../assets/parallex/clams.png';
import diamondShell1 from '../../assets/parallex/clams01.png';
import Submarine from '../../assets/parallex/Submarine.svg';
import Page2Plant1 from '../../assets/parallex/04.png';
import Page2Plant2 from '../../assets/parallex/11.png';
import Page3Plant1 from '../../assets/parallex/02.png';
import Page3Plant2 from '../../assets/parallex/09.png';
import Page3Plant3 from '../../assets/parallex/22.png';
import Page3Plant4 from '../../assets/parallex/15.png';
import Page3Plant5 from '../../assets/parallex/25.png';
import Octopus from '../../assets/parallex/12.png';
import Seahorse from '../../assets/parallex/19.png';
import FishImage13 from '../../assets/parallex/13.png';
import Rules from '../Rules/Rules';
import Tressure1 from '../Tressure/Tressure1';
import Tressure3 from '../Tressure/Tressure3';
import Tressure4 from '../Tressure/Tressure4';
import Tressure5 from '../Tressure/Tressure5';
import Tressure2 from '../Tressure/Tressure2';


export default function ParallexMobile() {
    const [faqs, setFaqs] = useState([
        {
            question: "How is a winner determined?",
            answer:
                "Its based on percentile system. The one who has answered the most amnount of questions in the least amount of time gets to be the winner. ",
            open: true
        },
        {
            question: "What if two people answer the same amount of questions in the same time?",
            answer: "We rack upto microseconds of the answer submission. The one with the least amount of time with the same score will win the prize. If even the micro seconds are the same then both of them are eleigible for the prize.",
            open: false
        },
        {
            question:
                "When do the prizes get announced?",
            answer: "Once the Quiz is conducted the results are tallied and announced in 24 hours.",
            open: false
        },
        {
            question:
                "How often are the quizzes conducted?",
            answer: "Silver tier quizzes are conducted every weeek, while the gold tier quizzes are conducted once every two weeks. The diamond tier quizzes are conducted every three months.",
            open: false
        },
        {
            question:
                "When can i attempt the quiz?",
            answer: "The date will be provided for every quiz. You will have a window of 24 hours.",
            open: false
        },
        {
            question:
                "How can i claim the prizes?",
            answer: "We will contact you with the number and mail id thats been used for registration and the prizes will be delivered to you in 7 working days.",
            open: false
        },
        {
            question: "Can you use the coins awarded to you to participate in any quiz?",
            answer: "Unfortunately not. Any and all coins that you received can only be used to upgrade you to challenge our diamond quiz. Once you reach the required amount of coins, you will be eligible to participate in a diamond quiz.",
            open: false
        }
    ]);
    const toggleFAQ = (index) => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }
                return faq;
            })
        );
    };
    useEffect(() => {
        let ctx = gsap.context(() => {
            var tlPage1 = gsap.timeline({
                defaults: { duration: 1},

                scrollTrigger: {
                    trigger: "#page1",
                    start: "top top",
                    end: "1000 bottom",
                    scrub: true,
                    pin: true,

                    // markers: true,
                },
            })
            tlPage1.to(".page1-content", {
                opacity: 0,
                scale: 0,
                duration: 5
            },"+=1")

            let tl = gsap.timeline({
                defaults: { duration: 1},

                scrollTrigger: {
                    trigger: "#page2",
                    start: "top 40%",
                    end: "bottom 20%",
                    scrub: true,
                    pin: true,

                    markers: true,
                },
            });

            gsap.set(".title2, .page3-title, .page3-title2, .page3-title3, .page4-title, .page4-title2, .page4-title3", { autoAlpha: 0, display: 'none', x: '0' });
            tl.addLabel("textout")
                .to(".title", { duration: 3, opacity: 0, display: 'none', x: "100" }, "+=1")
            // tl.from(".title", {
            //     opacity: 0,
            //     display: 'flex',
            //     duration: 1.5
            // }).to(".title", {
            //     opacity: 0,
            //     display: 'none',
            //     duration: 1.5
            // })

            let tl1 = gsap.timeline({
                defaults: { duration: 1 },

                scrollTrigger: {
                    trigger: "#page3",
                    start: "top top",
                    end: "500 bottom",
                    scrub: true,
                    pin: true,

                    // markers: true,
                },
            });
            tl1.addLabel("textout")
                .to(".title", { duration: 1.5, opacity: 0, display: 'none' })
                .addLabel("btnin")
                .to(".title2", { duration: 0.8, autoAlpha: 1, display: 'block' })

            
            let tl2 = gsap.timeline({
                defaults: { duration: 1, stagger: 2 },

                scrollTrigger: {
                    trigger: "#page4",
                    start: "top top",
                    end: "500 bottom",
                    scrub: true,
                    pin: true,

                    // markers: true,
                },
            });
            // tl1.from(".page3-title", {
            //     autoAlpha: 0,
            //     display: 'flex',
            //     duration: 1.5
            // }).to(".page3-title", {
            //     autoAlpha: 0,
            //     display: 'none',
            //     duration: 1.5
            // })
            // add animations and labels to the timeline
            
        })
    }, [])
    return (
        <div className=' parallexPage'>
            {/*  */}
            <div className="page page1" id="page1">
                <div className='page1-content'>
                    <h1 className='page-content-title'>Welcome to</h1>
                    <div class="Iam">
                        <b>
                            <div class="innerIam">
                                FUN<br />
                                EDUCATION<br />
                                ENTERTAINMENT<br />
                                COMPETITION<br />
                                PRIZES WORTH CRORES
                            </div>
                        </b>

                    </div>
                    <img className='page-logo' src={logo} alt="logo" />
                    <h3>We are an Edutainment platform</h3>

                </div>
                <div className='mountains'></div>
                {/* <img src={} alt="" /> */}
                <div class="ocean">
                    <div class="wave"></div>
                    <div class="wave wave2"></div>
                </div>
            </div>
            <div className="page page2" id="page2">
                <h1 className='title'>Congrats on getting your feet wet in the world of scholar sharks. This is a perfect place for you to start. </h1>
            </div>
            <div className='page page3' id="page3">
                <div className='title2'>
                    <h1 className=''>Here you will find silver fin sharks. There are weekly quizzes for all the sharks that will give out prizes worth 1 lakh.</h1>
                    {/* <div className="tressure">
                        <img src={coral} alt="coral" className='coral' />
                        <Tressure x="330deg" y="45deg" />
                        <Tressure2 x="330deg" y="360deg" />
                        <Tressure1 x="330deg" y="-45deg" />

                        <img src={coralClose} alt="coral-close" className='coral-close' />
                    </div> */}
                </div>
                {/* <img src={FishImage} alt="Fish" className='fishImage' />
                <img src={FishImage7} alt="Fish" className='fishImage7' />
                <img src={FishImage10} alt="Fish" className='fishImage10' />
                <img src={shark} alt="shark" className='sharkImg' />
                <img src={driver} alt="scubaDriver" className='scuba-driver' />
                <img src={Page2Plant1} alt="plants" className='page2-plant1' />
                <img src={Page2Plant2} alt="plants" className='page2-plant2' /> */}
                {/* <div className='silverCoral-container'>
                    <div className='silverCoral-block'>
                        <div class="ball">
                        </div>
                        <img src={coral} alt="coral" className='silverCoral' />
                    </div>
                </div>
                <div className='bubble-container bubble-container1'>
                    <div id="bubble2" class="gradientBubble"></div>
                    <div id="bubble" class="gradientBubble"></div>
                    <div id="bubble3" class="gradientBubble"></div>
                    <div id="bubble4" class="gradientBubble"></div>
                </div> */}
                {/* <Fish reverse={false} />
                <Fish reverse={true} /> */}
            </div>
            <div className="page page4" id="page4">
                <h1 className='page3-title'>
                    Here there are true knowledgeable apex predators who have swallowed an ocean worth of knowledge.
                </h1>
            </div>
            <div className='page page5' id="page5">
                <h1 className='page3-title2'>
                    It is said that those who don't know their history are doomed to repeat it.
                    -Napolean Bonaparte.
                </h1>
            </div>
            <div className='page page6' id="page6">
                <div className='page3-title3'>

                    <h1 className=''>
                        If you knew the ins and outs of this quote even before completely reading the entire text, then you are at the right place. Here there are entire sections of quizzes that are conducted biweekly with prizzes worth 2 lakhs, related to rich Indian history and mythology.
                    </h1>
                    {/* <div className="tressure">
                        <img src={coral} alt="coral" className='coral' />
                        <Tressure3 x="330deg" y="45deg" type={"golden"} />
                        <Tressure5 x="330deg" y="360deg" type={"golden"} />
                        <Tressure4 x="330deg" y="-45deg" type={"golden"} />

                        <img src={coralClose} alt="coral-close" className='coral-close' />
                    </div> */}
                </div>
                {/* <img src={Octopus} alt="Octopus" className='octopus' />
                <img src={FishImage13} alt='fish' className='fishImage13' />
                <img src={Page3Plant1} alt="plants" className='page3-plant1 ' />
                <img src={Page3Plant2} alt="plants" className='page3-plant2 ' />
                <img src={Page3Plant3} alt="plants" className='page3-plant3 ' />
                <img src={Page3Plant4} alt="plants" className='page3-plant4 ' />
                <img src={Page3Plant5} alt="plants" className='page3-plant5 ' />
                <img src={Seahorse} alt="Seahorse" className='seahorse ' />

                <div className='bubble-container bubble-container2'>
                    <div id="bubble2" class="gradientBubble"></div>
                    <div id="bubble" class="gradientBubble"></div>
                    <div id="bubble3" class="gradientBubble"></div>
                    <div id="bubble4" class="gradientBubble"></div>
                </div>
                <div className='silverCoral-container'>
                    <div className='silverCoral-block'>
                        <div class="ball">
                        </div>
                        <img src={coralGolden} alt="coral" className='silverCoral' />
                    </div>
                </div>
                <Fish reverse={false} />
                <Fish reverse={true} />
                <img src={Tortoise} alt="tortoise" className='tortoise' /> */}
            </div>
            <div className="page page7" id="page7">
                <h1 className='page4-title'>Stablility in life is obtained by financial freedom.</h1>
            </div>
            <div className='page page8' id="page8">
                <h1 className='page4-title2'>This deep part of ocean is where people who weigh their knowledge in diamond naturally arrive. If you know how to traverse the treacherous waters of the financial world, then this is where you should be swimming. </h1>
            </div>
            <div className='page page9' id="page9">
                <h1 className='page4-title3'>What are you waiting for? Mercedes, Thars, Royal Enfields, tablets, laptops, phones, etc., we dish out all the prizes in one go as a bumper crop worth 15cr. The only question is, Do you trust in your knowledge enough to bet on yourself? Nothing is more expensive than a missed opportunity!!!!</h1>
                {/* <img src={diamondShell} alt="" className='page4-title4' /> */}
                {/* <img src={diamondShell1} alt="" className='shell' />
                <Fish reverse={false} />
                <Fish reverse={true} />
                <div class="bubblewhirl"></div>
                <div className='submarine'>
                    <div class="content">
                        <img src={Submarine} className='submar' />
                    </div>
                </div>
                <img src={Page3Plant1} alt="plants" className='page4-plant1 ' /> */}
            </div>
            <div className="page page10" id="page10">
                <h1 style={{ textAlign: 'center', textDecoration: 'underline' }}>Mr. Fin with HIS interest....</h1>
                <Mascot />
            </div>
            <div className="page page11" id="page11">
                <h1 className='section-title'>Rules of Engagement</h1>
                <Rules />
            </div>
            <div className="page page12" id="page12">
                <h1 className='section-title'>FAQ's</h1>
                <div className="faqs">
                    {faqs.map((faq, index) => (
                        <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                </div>
            </div>

            <div className="page page13" id="page13">
                <h1 className='section-title'>Our Partners</h1>
                <InfiniteCarousal />
            </div>
        </div>
    )
}
