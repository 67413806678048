import React from 'react'
import './index.css';

export default function InfiniteCarousal() {
    return (
        <>
            <div className="carousel-container">
                <div className="infinitecarousel">
                    <div className="carousel__list">
                    <i className="carousel__item fa-brands fa-apple" style={{ "--position": 1 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-windows" style={{ "--position": 2 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-dribbble" style={{ "--position": 3 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-airbnb" style={{ "--position": 4 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-amazon" style={{ "--position": 5 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-linkedin" style={{ "--position": 6 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-facebook" style={{ "--position": 7 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-twitter" style={{ "--position": 8 } as React.CSSProperties}></i>    
                    </div>
                </div>
                <br />
                <br/>
                <br className='mobile-only'/>
                <br className='mobile-only'/>
                <div className="infinitecarousel" data-reverse="true">
                    <div className="carousel__list">
                    <i className="carousel__item fa-brands fa-apple" style={{ "--position": 1 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-windows" style={{ "--position": 2 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-dribbble" style={{ "--position": 3 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-airbnb" style={{ "--position": 4 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-amazon" style={{ "--position": 5 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-linkedin" style={{ "--position": 6 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-facebook" style={{ "--position": 7 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-twitter" style={{ "--position": 8 } as React.CSSProperties}></i>
                    </div>
                </div>
                <br />
                <br/>
                <br className='mobile-only'/>
                <br className='mobile-only'/>
                <div className="infinitecarousel mobile-only" data-reverse="false">
                    <div className="carousel__list">
                        <i className="carousel__item fa-brands fa-apple" style={{ "--position": 1 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-windows" style={{ "--position": 2 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-dribbble" style={{ "--position": 3 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-airbnb" style={{ "--position": 4 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-amazon" style={{ "--position": 5 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-linkedin" style={{ "--position": 6 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-facebook" style={{ "--position": 7 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-twitter" style={{ "--position": 8 } as React.CSSProperties}></i>
                    </div>
                </div>
                <br />
                <br/>
                <br className='mobile-only'/>
                <br className='mobile-only'/>
                <div className="infinitecarousel mobile-only" data-reverse="true">
                    <div className="carousel__list">
                    <i className="carousel__item fa-brands fa-apple" style={{ "--position": 1 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-windows" style={{ "--position": 2 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-dribbble" style={{ "--position": 3 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-airbnb" style={{ "--position": 4 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-amazon" style={{ "--position": 5 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-linkedin" style={{ "--position": 6 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-facebook" style={{ "--position": 7 } as React.CSSProperties}></i>
                        <i className="carousel__item fa-brands fa-twitter" style={{ "--position": 8 } as React.CSSProperties}></i>
                    </div>
                </div>
            </div>
        </>
    )
}
