import React from 'react'
import './index.css';
import SilverLeaderboard from '../../assets/silverLeaderboard.json';
import GoldLeaderboard from '../../assets/goldLeaderboard.json';
import DiamondLeaderboard from '../../assets/diamondLeaderboard.json';

export default function Leaderboard({ type }) {
    const List = `${type}Leaderboard`;
    console.log(List);
    return (
        <div>
            <main>
                <div id="header">
                    <h1>{type} Ranking</h1>
                    {/* <button class="share">
                        <i class="ph ph-share-network"></i>
                    </button> */}
                </div>
                <div id="leaderboard">
                    <div class="ribbon"></div>
                    <table>
                        {
                            
                            type === 'Silver' &&  
                                SilverLeaderboard.map((l, index) => (
                                    <tr>
                                        <td class="number">{index + 1}</td>
                                        <td class="name">{l.name}</td>
                                        <td className='ans'>
                                            {l.points}
                                        </td>
                                        <td class="points">
                                            {l.duration}
                                            {index === 0 ? <img class="gold-medal" src="https://github.com/malunaridev/Challenges-iCodeThis/blob/master/4-leaderboard/assets/gold-medal.png?raw=true" alt="gold medal" /> : ""}
                                        </td>
                                    </tr>
                                ))
                        }
                        {
                            type === 'Gold' &&  
                                GoldLeaderboard.map((l, index) => (
                                    <tr>
                                        <td class="number">{index + 1}</td>
                                        <td class="name">{l.name}</td>
                                        <td className='ans'>
                                            {l.points}
                                        </td>
                                        <td class="points">
                                            {l.duration}
                                            {index === 0 ? <img class="gold-medal" src="https://github.com/malunaridev/Challenges-iCodeThis/blob/master/4-leaderboard/assets/gold-medal.png?raw=true" alt="gold medal" /> : ""}
                                        </td>
                                    </tr>
                                ))
                        }
                        {
                            type === 'Diamond' &&  
                                DiamondLeaderboard.map((l, index) => (
                                    <tr>
                                        <td class="number">{index + 1}</td>
                                        <td class="name">{l.name}</td>
                                        <td className='ans'>
                                            {l.points}
                                        </td>
                                        <td class="points">
                                            {l.duration}
                                            {index === 0 ? <img class="gold-medal" src="https://github.com/malunaridev/Challenges-iCodeThis/blob/master/4-leaderboard/assets/gold-medal.png?raw=true" alt="gold medal" /> : ""}
                                        </td>
                                    </tr>
                                ))
                        }

                        {/* <tr>
                            <td class="number">2</td>
                            <td class="name">Tejas</td>
                            <td className='ans'>
                                10
                            </td>
                            <td class="points">1 min 40 sec</td>
                        </tr>
                        <tr>
                            <td class="number">3</td>
                            <td class="name">Anand</td>
                            <td className='ans'>
                                10
                            </td>
                            <td class="points">1 min 45 sec</td>
                        </tr>
                        <tr>
                            <td class="number">4</td>
                            <td class="name">Jyoti</td>
                            <td className='ans'>
                                10
                            </td>
                            <td class="points">1 min 55 sec</td>
                        </tr>
                        <tr>
                            <td class="number">5</td>
                            <td class="name">Yogita</td>
                            <td className='ans'>
                                9
                            </td>
                            <td class="points">1 min 30 sec</td>
                        </tr> */}
                    </table>

                </div>
            </main>
        </div>
    )
}
