import React, { useContext, useState, useLayoutEffect, useEffect } from "react";
import Lighthouse from "../Components/Lighthouse/Lighthouse";
import shark from '../assets/ThumbsUpAlphaGIF.gif';
import sharkIdle from '../assets/IdleAlphaGIF.gif';
import logo from '../assets/logo.png';
import { Link, useNavigate } from "react-router-dom";
import { PlansContext } from "../Context";
import leaders from '../assets/leader.json';
import Layout from "./Layout";
import Userpool from "../Userpool";


function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

function LeaderboardPage() {
    const { answer, ansCount } = useContext(PlansContext);
    const [width, height] = useWindowSize();
    const [panelHeight, setPanelHeight] = useState<number>();
    const navigate = useNavigate();
    const [selected, setSelected] = useState(false);

    const clickHandler = (type: any) => {
        setSelected(true);
        setTimeout(() => navigate(`/${type}`), 2000)
        
    }  

    console.log(ansCount);
    const getCurrentUser = () => {
        const cognitoUser = Userpool.getCurrentUser();

        if (!cognitoUser) {
            
            navigate("/login");
            return false
        }

        cognitoUser.getSession((err: any, session: any) => {
            if (err) {
                console.log(err);
                return;
            }

            // console.log("Session valid:" + session.isValid());
            // console.log(session);
            // setUser(session.accessToken.payload.username)
            // setUserInfo({
            //     user: session.accessToken.payload.username
            // })

            // cognitoUser.getUserAttributes((err: any, attr: any) => {
            //     if (err) return console.log(err);

            //     callback(attr);
            // })

            // fetchPlans(session.accessToken.payload.username)
        })
    }

    useEffect(() => {
        // var sceneHeight = document.getElementsByClassName('deep-sea-bg')[0].clientHeight;
        var panelHeaderHeight = document.getElementById('panel-header')?.clientHeight || 0;
        var panelStaticHeight = document.getElementById('static-height')?.clientHeight || 100;
        var PanelBodyHeight = panelHeaderHeight - panelStaticHeight - 200;
        setPanelHeight(PanelBodyHeight);
        getCurrentUser();
    }, [])

    return (
        <Layout>
            <div className='panel leader-page'>
                <img src={selected ? shark : sharkIdle} alt="shark" style={{height: '400px'}}/>

                <div>
                    <h1 style={{textDecoration: "underline",fontWeight: "bold", marginBottom: "20px"}}>Congratulations shark</h1>
                    <p style={{marginBottom: "10px"}}>You have correctly answered {ansCount > 0 ? ansCount : 0} out of 10.</p>
                    <p>You can either directly <b>challenge the main quiz</b> or <b>take the tutorial again</b></p>
                    <div className='carBtn-grp leadBtn-grp'>
                        <button className='carBtn pulse-btn-car' onClick={() => clickHandler('plans')}>I am confident !!! CHALLENGE NOW</button>
                        <button className='carQuizBtn pulse-btn-car' onClick={() => clickHandler('silverQuiz')}>Let me Practice some more</button>
                        <button className='carQuizBtn pulse-btn-car' onClick={() => clickHandler('home')}>Practice other quiz</button>
                    </div>
                </div>     
            </div>
        </Layout>
    )
}

export default LeaderboardPage;