import { useState, useLayoutEffect, useContext, useEffect } from 'react';
import './index.css';
// import questions from '../../assets/cricket.json';
import Question from '../Question/Question';
import Result from '../Result/Result';
import Lighthouse from '../Lighthouse/Lighthouse';
import { useNavigate, useLocation } from 'react-router-dom';
import { PlansContext } from '../../Context';
import sharkVideo from '../../assets/IdleAlphaWEBM.webm';
import sharkThumpsUpVideo from '../../assets/ThumbsUpAlphaWEBM.webm';
import sharkThumpsDownVideo from '../../assets/ThumbsDownAlphaWEBM.webm';
import staticshark from '../../assets/IdleAlphaGIF.gif';
import thumpsup from '../../assets/ThumbsUpAlphaGIF.gif';
import thumpsdown from '../../assets/ThumbsDownAlphaGIF.gif';
import logo from '../../assets/logo.png';
import axios from 'axios';
import Timer from '../Timer/Timer';
import CButton from '../CButton/CButton';
import { Box, Button, Modal, Typography } from '@mui/material';
import usePersistState from '../../usePersistState';
import Userpool from '../../Userpool';
// import Timer from '../Timer/Timer';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#13568a',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}


function SilverQuizPage() {
    const { setAnsCount, setAnswer, userDetails } = useContext(PlansContext);
    const [width, height] = useWindowSize();
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [userAnswers, setUserAnswers] = useState<any>([]);
    const [explanation, setExplanation] = useState(false);
    const [nextDisabled, setNextDisabled] = useState(true);
    const [showShark, setShowShark] = useState('staticshark');
    const [disabled, setDisabled] = useState(false);
    const [randomObject, setRandomObject] = useState(null);
    const [windowWidth, setWindowWidth] = useState<number>(0);
    const [questions, setQuestions] = useState([]);
    const location = useLocation();
    const [showTimer, setShowTimer] = useState(true);
    const [displayPage, setDisplayPage] = useState(false);
    const [open, setOpen] = useState(false);
    const [userData, setUserData] = useState({});
    const handleClose = () => {
        setOpen(false);
        navigate('/leaderboard');
    }


    // set time for each question
    const [timer, setTimer] = useState(20);
    const [quizStarted, setQuizStarted] = useState(false);
    const [isLastq, setIsLastq] = useState(false);
    // const timerPer = `(${timer} * 10)%`
    var startTime: any;
    var endTime: any;
    const timePerQuestion: any[] = [];

    const type = localStorage.getItem('quizType') || 'food'

    const getCurrentUser = () => {
        const cognitoUser = Userpool.getCurrentUser();

        if (!cognitoUser) {
            
            navigate("/login");
            return false
        }

        cognitoUser.getSession((err: any, session: any) => {
            if (err) {
                console.log(err);
                return;
            }

            // console.log("Session valid:" + session.isValid());
            // console.log(session);
            // setUser(session.accessToken.payload.username)
            // setUserInfo({
            //     user: session.accessToken.payload.username
            // })

            // cognitoUser.getUserAttributes((err: any, attr: any) => {
            //     if (err) return console.log(err);

            //     callback(attr);
            // })

            // fetchPlans(session.accessToken.payload.username)
        })
    }

    useEffect(() => {
        // windowHeight = document.getElementsByTagName('body')[0].clientWidth;
        getCurrentUser()
        setTimeout(() => {
            setDisplayPage(true)
        }, 1000)
        setWindowWidth(window.innerWidth);
        fetchQuiz();
        updatePractice()
        
    }, []);

    const updatePractice = async () => {

        // var plan = {}
        const userId = localStorage.getItem("CognitoIdentityServiceProvider.34dav3smbul7osaq8q82n7tb06.LastAuthUser")

        await axios.get(`https://iy5ispsidmfhpzojalaofamqiq0nerep.lambda-url.ap-south-1.on.aws/getplans/${userId}`)
            .then((res: any) => {
                console.log(res.data[0])
                // setUserData(res.data[0])
                updatePracticeCount(res.data[0]);
            })
            .catch((error: any) => {
                console.log("error at", error)
            })

        // CognitoIdentityServiceProvider.34dav3smbul7osaq8q82n7tb06.LastAuthUser
        
        
    }

    const updatePracticeCount = async (userDetails: any) => {
        let data = JSON.stringify({
            //@ts-ignore
            userId: userDetails.userId,
            //@ts-ignore
            planid: userDetails.planid,
            //@ts-ignore
            planType: userDetails.planType,
            //@ts-ignore
            isActive: userDetails.isActive,
            //@ts-ignore
            from: userDetails.from,
            //@ts-ignore
            to: userDetails.to,
            //@ts-ignore
            isUpgraded: userDetails.isUpgraded,
            //@ts-ignore
            createdOn: userDetails.createdOn,
            //@ts-ignore
            updatedOn: userDetails.updatedOn,
            //@ts-ignore
            value1: userDetails.value1,
            //@ts-ignore
            value2: userDetails.value2,
            //@ts-ignore
            value3: (parseInt(userDetails.value3) + 1).toString(),
            //@ts-ignore
            value4: userDetails.value4,
            //@ts-ignore
            value5: userDetails.value5
        })

        console.log(userDetails);

        let config = {
            method: "post",
            maxBosyLength: Infinity,
            url: "https://iy5ispsidmfhpzojalaofamqiq0nerep.lambda-url.ap-south-1.on.aws/updateplan",
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': '3Cp28MeCN19CEJYoox59pamRkuFcj72l3RFdxAYa'
            },
            data: data
        }

        await axios.request(config)
            .then(response => {
                console.log(JSON.stringify(response.data))
                // handleRazorpayScreen(response.data.amount)
            })
            .catch(error => {
                console.log("error at", error)
            })
    }

    const fetchQuiz = async () => {
        await axios.get(`https://zwhxhdbmy25zqawk7h4wohtbda0vwaoy.lambda-url.ap-south-1.on.aws/prequiz${(type === 'mythology' || type === 'history' || type === 'finance') ? 'new' : ''}category/eng/10/${type}`)
            .then((res: any) => {
                setQuestions(res.data.questions);
                // handleRazorpayScreen(response.data.amount)

            })
            .catch((error: any) => {
                console.log("error at", error)
            })
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setShowTimer(false);
        }, 4500);

        return () => clearInterval(interval);
    }, []);



    useEffect(() => {
        if (!showTimer) {
            const interval = setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer > 0) {
                        // endTime = new Date();
                        // console.log('quiz end', new Date());
                        return prevTimer - 1;

                    } else {
                        if (currentQuestion !== questions.length) {
                            setCurrentQuestion(prevQuestion => prevQuestion + 1);

                        }

                        if ((questions.length - 1) === currentQuestion) {
                            navigate('/leaderboard')
                        }

                        setNextDisabled(true);

                        // startTime = new Date()
                        // Reset timer for the next question
                        return 20;
                    }
                });
            }, 1000);

            // timePerQuestion.push((endTime - startTime));

            // console.log(timePerQuestion);
            setExplanation(false);
            setDisabled(false);
            setShowShark('staticshark');
            // setNextDisabled(true);

            return () => clearInterval(interval);
        }



    }, [currentQuestion, quizStarted, showTimer]);

    const navigate = useNavigate();

    const onAnswerCheck = (e: any, question: any, text: any) => {
        console.log(question);
        console.log(text);
        if (text === question.rightAnswer) {
            e.target.classList.add('correct');
            // const newAnswer = { isCorrect: true };
            setUserAnswers([...userAnswers, true]);
            setShowShark('thumpsup');
        } else if (text !== question.rightAnswer) {
            e.target.classList.add('wrong');
            setShowShark('thumpsdown');
            setUserAnswers([...userAnswers, false]);
            var elements = document.getElementsByClassName('answers');

            for (var i = 0; i < elements.length; i++) {
                if (elements[i].innerHTML === question.rightAnswer) {
                    elements[i].classList.add('correct')
                }
            }
        }

        console.log(userAnswers);

        setExplanation(true);
        setNextDisabled(false);
        setDisabled(true);
    }


    // Keep all of the logic in App.tsx 

    const handleNextQuestion = () => {
        setCurrentQuestion(currentQuestion + 1);

        setExplanation(false);
        setNextDisabled(true);
        setShowShark('staticshark');
        setDisabled(false);
        setTimer(20);

        var elements = document.getElementsByClassName('answers')

        for (var i = 0; i < elements.length; i++) {
                elements[i].classList.remove('correct')
                elements[i].classList.remove('wrong')
        }

        if ((questions.length - 1) === currentQuestion) {
            navigate('/leaderboard');

            const count = userAnswers.reduce((count: number, currentValue: boolean) => {
                return currentValue === true ? count + 1 : count;
            }, 0);

            setAnswer(userAnswers);
            setAnsCount(count);
        }
    }

    const resetQuiz = () => {
        setCurrentQuestion(0);
        setUserAnswers([]);
    }

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = height * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    let vhWidth = width * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vhwidth', `${vhWidth}px`);

    document.documentElement.style.setProperty('--timerPer', `${timer * 10}%`);

    useEffect(() => {

        const interval = setInterval(() => {
            if (document.visibilityState === 'hidden') {

                const count = userAnswers.reduce((count: number, currentValue: boolean) => {
                    return currentValue === true ? count + 1 : count;
                }, 0);

                setAnswer(userAnswers);
                setAnsCount(count);
                clearInterval(interval);
                // alert('You broke the rule hence you are disqualified. do not change tab again');
                // navigate('/leaderboard');
                setOpen(true);
            };
        }, 1000);

        return () => clearInterval(interval);
    }, [userAnswers]);

    return (
        <>
            {
                displayPage &&
                <>
                    <Lighthouse light />
                    {
                        showTimer &&
                        <Timer />
                    }
                    {
                        !showTimer &&
                        <div className='quizPanel'>
                            <div className='quizPanel-left'>
                                <div className='quizPanel-section'>
                                    <div className='quizPanel-logo'>
                                        <img src={logo} alt="logo" />
                                    </div>
                                    {/* <div className='quizPanel-text'>
                            <h1>Unlesh your knowledge</h1>
                            <h4>Where learning takes a dive</h4>
                        </div> */}
                                    {/* <div className=''>
                                        {showShark === 'staticshark' && windowWidth < 991 &&
                                            <img src={staticshark} alt="shark" className='sharkGif' />
                                        }
                                        {showShark === 'thumpsup' && windowWidth < 991 &&
                                            <img src={thumpsup} alt="shark" className='sharkGif' />
                                        }
                                        {showShark === 'thumpsdown' && windowWidth < 991 &&
                                            <img src={thumpsdown} alt="shark" className='sharkGif' />
                                        }
                                    </div> */}
                                </div>
                                {/* <div className='timer-block'> */}
                                {/* <Timer /> */}
                                {/* <div className='countdown'>
                            <div className="countdown__fill" id="ticker"></div>
                            <div className="countdown__digit" id="seconds">{timer}</div>
                            
                        </div> */}
                                <div className='silverQuiz-shark'>
                                    <div className='silverQuiz-shark-img-container'>
                                        {showShark === 'staticshark' && 
                                            <img src={staticshark} alt="shark" className='sharkGif' />
                                        }
                                        {showShark === 'thumpsup' && 
                                            <img src={thumpsup} alt="shark" className='sharkGif' />
                                        }
                                        {showShark === 'thumpsdown' && 
                                            <img src={thumpsdown} alt="shark" className='sharkGif' />
                                        }
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                            <div className='quizPanel-right'>

                                Time Remaining: {timer}

                                {
                                    currentQuestion < questions.length &&
                                    <Question disabled={disabled} number={currentQuestion} nextDisabled={nextDisabled} question={questions[currentQuestion]} explanation={explanation} onAnswerCheck={onAnswerCheck} onAnswerClick={handleNextQuestion} />
                                }
                                {
                                    currentQuestion === questions.length && <h1>Welcome</h1>
                                }

                                {/* <div className='mobile-display'>
                        {showShark === 'static' &&
                            <video className='video' width="100%" height="100%" loop autoPlay muted>
                                <source src={sharkVideo} type="video/webm" />
                            </video>
                        }
                        {showShark === 'thumpsup' &&
                            <video className='video' width="100%" height="100%" loop autoPlay muted>
                                <source src={sharkThumpsUpVideo} type="video/webm" />
                            </video>
                        }
                        {showShark === 'thumpsdown' &&
                            <video className='video' width="100%" height="100%" loop autoPlay muted>
                                <source src={sharkThumpsDownVideo} type="video/webm" />
                            </video>
                        }
                    </div> */}
                            </div>
                        </div>
                    }
                </>
            }

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        You broke the rule hence you are disqualified. Do not change the tabs while attempting quiz.
                    </Typography>
                    <Button variant='contained'  sx={{ mt: 2 }} color='warning' onClick={() => handleClose()}>Ok</Button>
                </Box>
            </Modal>
        </>
    )
}

export default SilverQuizPage
