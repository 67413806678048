import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import './index.css';
import { Link, useNavigate } from "react-router-dom";
import Lighthouse from '../Lighthouse/Lighthouse';
import logo from '../../assets/logo.png';
import { ReactTyped, Typed } from "react-typed";
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import intro from '../../assets/bg-vid-1.mp4';
import introOne from '../../assets/bg-vid-2.mp4';
import bgMusic from '../../assets/bg-audio.mp3';
import { isMobile } from 'react-device-detect';
import introMob from '../../assets/bg-video-mob-end.mp4'

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function Language() {

  const [width, height] = useWindowSize();
  const [typed, setTyped] = useState<Typed | undefined>();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [changeVid, setChangeVid] = useState(false);
  const videoRef = useRef();
  const [audioMute, setAudioMute] = useState(false);

  // var audio = new Audio(bgMusic);

//   const Playit = (type: string) => {
    
//     if(type === 'play') {
      
//       audio?.play();
//     }
//     else {
//       audio?.pause();
//     }
    
// }
useEffect(() => {
  // audio?.play();
  // return () => { Playit('stop'); }
}, []);

  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = height * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  let vhWidth = width * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vhwidth', `${vhWidth}px`);

  const showLang = () => {
    let shark = document.getElementById('shark-talk');
    let lang = document.getElementById('lang-selector');

    shark?.classList.add("shark-animation");
    lang?.classList.add("lang-animation");
  }



  function fadeOutEffect() {
    var fadeTarget = document.getElementById("lang-selector");
    var fadeEffect = setInterval(function () {
        if (!fadeTarget?.style.opacity) {
          //@ts-ignore
            fadeTarget.style.opacity = 1;
        }
        //@ts-ignore
        if (fadeTarget.style.opacity > 0) {
          //@ts-ignore
            fadeTarget.style.opacity -= 0.1;
        } else {
            clearInterval(fadeEffect);
        }
    }, 200);
}



  const changeLanguage = (lng: any) => {
    setChangeVid(true)
    // i18n.changeLanguage(lng);
    // navigate('/login')
    // document.getElementById("lang-selector").
   fadeOutEffect();
   setTimeout(() => {
    navigate('/home')
   }, 3500)
  };

  useEffect(() => { 
    
    //@ts-ignore
    videoRef.current?.load();
    
  }, [changeVid]);

  document.onmousemove = e => {
    for (const card of document.getElementsByClassName('card-glow-border') as HTMLCollectionOf<HTMLElement>) {
      const rect = card.getBoundingClientRect(),
        x = e.clientX - rect.left,
        y = e.clientY - rect.top;

      card.style.setProperty("--mouse-x", `${x}px`);
      card.style.setProperty("--mouse-y", `${y}px`);
    }
  }

  // const muteHandler = () => {
  //   setAudioMute(!audioMute);
  //   console.log(audioMute);
  //   if(audioMute) {
  //     audio.src = "";
  //   } else {
  //     audio.play();
  //   }
  // }


  return (
    <div className="lang-page">
      {/* <div className='mute' onClick={() => muteHandler()}>
        {
          audioMute && 
          <i className="fa-solid fa-volume-high" ></i>
        }

        {
          !audioMute && 
          <i className="fa-solid fa-volume-xmark"></i>
        }
        
                        
      </div> */}
      {
        //@ts-ignore
        <video className={`videoContainer ${!changeVid ? 'langVid' : ''}`} ref={videoRef} width="100%" height="100%" controls={false} muted autoPlay loop>
          <source src={!changeVid ? intro : isMobile ? introMob : introOne} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      }
      
      <Link to="/">
        <img className='logo' src={logo} />
      </Link>
      


      
      <motion.div initial={{ opacity: 0, y: 50, x: 100 }} animate={{ opacity: 1, x: 0, y: 0 }} transition={{
        ease: "linear",
        duration: 1,
        x: { duration: 1 }
      }} className='lang-selector card-glow-border glow-on-hover lang-animation' id="lang-selector">
        <div className='card-glow-border-inner '>
          <div>
            {/* <h1>
            Welcome to Scholarsharks
          </h1>
          <h4>Please select your Language</h4> */}
            <h1 style={{color: "#fff"}}>{t('welcome')}</h1>
            <p>Please select your Language</p>
          </div>

          <div className='button-container'>
            <button className='button' onClick={() => changeLanguage('en')}>English</button>
            <button className='button disabled' onClick={() => changeLanguage('hi')}>हिंदी <span className='coming-text'>Coming soon</span></button>
            <button className='button disabled' onClick={() => changeLanguage('mr')}>मराठी <span className='coming-text'>Coming soon</span></button>
          </div>
        </div>
      </motion.div>
      <div className='rotate-screen'>
        <h1>Please rotate a screen for best experience</h1>
      </div>

    </div>
  )
}

export default Language
