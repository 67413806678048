import './App.css';
import Quizpage from './Components/Quizpage/Quizpage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PlansPage from './Pages/PlansPage';
import RegisterPage from './Pages/RegisterPage';
import LoginPage from './Pages/LoginPage';
import LanguagePage from './Pages/LanguagePage';
import LeaderboardPage from './Pages/LeaderboardPage';
import DashboardPage from './Pages/DashboardPage';
import { PlansContext } from './Context';
import SummaryPage from './Pages/SummaryPage';
import SilverQuizPage from './Components/SilverQuizPage/SilverQuizPage';
import Videopage from './Components/Videopage/Videopage';
import HomePage from './Pages/HomePage';
import AnimatedRoutes from './AnimatedRoutes';
import { useState } from 'react';



function App() {

  const [plans, setPlans] = useState([]);
  const [answer, setAnswer] = useState(0);
  const [ansCount, setAnsCount] = useState([]);
  const [info, setInfo] = useState([]);
  const [userInfo, setUserInfo] = useState("");
  const [userDetails, setUserDetails] = useState({});

  return (
    <div className="App">
      <PlansContext.Provider value={{ plans, setPlans, answer, setAnswer, ansCount, setAnsCount, info, setInfo, userInfo, setUserInfo, userDetails, setUserDetails }}>
        <BrowserRouter>
          <AnimatedRoutes />
        </BrowserRouter>
      </PlansContext.Provider>
    
      {/* <Language /> */}
      {/* <Videopage /> */}
      {/* <Quizpage /> */}
    </div>
  );
}

export default App;
