import React from 'react'
import cricket from '../../assets/parallex/Cricket.png';
import travel from '../../assets/parallex/Travel.png';
import foodie from '../../assets/parallex/Foodie.png';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import * as $ from "jquery"
import OwlCarousel from 'react-owl-carousel';
import './index.css';

export default function Mascot() {

  const options = {
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      800: {
        items: 2,
      },
      1000: {
        items: 3,
      }
    },
    nav: false,
    dots: true,
    autoplay: false
  }


  return (
    <div className='mascot-container'>
      {/* <div className='mascot-block'>
        <img src={cricket} alt="cricket" className='mascot-img' />
        <div className='mascot-content'>
            <h1>Did You Know?</h1>
            <p>Cricket is the second most popular sport after soccer/football with an estimated fan base of 2.5 billion people. If you are one such fan then challenge yourself among your peers and win assured prizes every week.</p>
        </div>
      </div>
      <div className='mascot-block'>
        <img src={travel} alt="cricket" className='mascot-img' />
        <div className='mascot-content'>
            <h1>Did You Know?</h1>
            <p>Cricket is the second most popular sport after soccer/football with an estimated fan base of 2.5 billion people. If you are one such fan then challenge yourself among your peers and win assured prizes every week.</p>
        </div>
      </div>
      <div className='mascot-block'>
        <img src={cricket} alt="cricket" className='mascot-img' />
        <div className='mascot-content'>
            <h1>Did You Know?</h1>
            <p>Cricket is the second most popular sport after soccer/football with an estimated fan base of 2.5 billion people. If you are one such fan then challenge yourself among your peers and win assured prizes every week.</p>
        </div>
      </div>
      <div className='mascot-block'>
        <img src={cricket} alt="cricket" className='mascot-img' />
        <div className='mascot-content'>
            <h1>Did You Know?</h1>
            <p>Cricket is the second most popular sport after soccer/football with an estimated fan base of 2.5 billion people. If you are one such fan then challenge yourself among your peers and win assured prizes every week.</p>
        </div>
      </div>
      <div className='mascot-block'>
        <img src={cricket} alt="cricket" className='mascot-img' />
        <div className='mascot-content'>
            <h1>Did You Know?</h1>
            <p>Cricket is the second most popular sport after soccer/football with an estimated fan base of 2.5 billion people. If you are one such fan then challenge yourself among your peers and win assured prizes every week.</p>
        </div>
      </div> */}
      <OwlCarousel className='owl-theme' loop margin={20} autoplay {...options}>

        {/* 1 */}
        <div className="review item">
          <h3>Mr. AshFin</h3>
          <div className="review-img">
            <img src={cricket} alt="" className='mascot-img'/>
          </div>
          <p>Cricket is the second most popular sport after soccer/football with an estimated fan base of 2.5 billion people. If you are one such fan then challenge yourself among your peers and win assured prizes every week.</p>

        </div>

        {/* 2 */}
        <div className="review item">
          <h3>Mr. Travel Fin</h3>
          <div className="review-img">
            <img src={travel} alt="" className='mascot-img'/>
          </div>
          <p>"The world's longest commercial flight took around 30 hours which ran from Australia to Sri Lanka from 1943-45, often lasted over 30 hours,. Today the longest commercial flight is the Singapore Airlines Singapore to New York route, with an average journey time of 17 hours and 50 minutes. Today the entire world has shrunk for travellers. Now we are here to make such travels a even more sweeter by bestowing you with gifts worth 2 Lakhs every two weeks. Get your travel vouchers with us NOW!!!!”</p>

        </div>


        {/* 3 */}
        <div className="review item">
          <h3>Mr. Foodie Fin</h3>
          <div className="review-img">
            <img src={foodie} alt="" className='mascot-img'/>
          </div>
          <p>There's more water in cucumber than watermelon. Okay, not by much, but this was still a fun food fact to us! Watermelon is about 92% water, while cucumber is 95%. If you are the kinda of person who knows even such obscure facts, then we have fun filled quiz which will win you prizes that will reward you for just being a foodie.</p>

        </div>

        <div className="review item">
          <h3>Did You Know?</h3>
          <div className="review-img">
            <img src={cricket} alt="" className='mascot-img'/>
          </div>
          <p>Cricket is the second most popular sport after soccer/football with an estimated fan base of 2.5 billion people. If you are one such fan then challenge yourself among your peers and win assured prizes every week.</p>

        </div>

        <div className="review item">
          <h3>Did You Know?</h3>
          <div className="review-img">
            <img src={cricket} alt="" className='mascot-img'/>
          </div>
          <p>Cricket is the second most popular sport after soccer/football with an estimated fan base of 2.5 billion people. If you are one such fan then challenge yourself among your peers and win assured prizes every week.</p>

        </div>

        <div className="review item">
          <h3>Did You Know?</h3>
          <div className="review-img">
            <img src={cricket} alt="" className='mascot-img'/>
          </div>
          <p>Cricket is the second most popular sport after soccer/football with an estimated fan base of 2.5 billion people. If you are one such fan then challenge yourself among your peers and win assured prizes every week.</p>

        </div>
      </OwlCarousel>
    </div>
  )
}
