import React, { useEffect, useState } from 'react';
import ThreeDCarousel from '../Components/ThreeDCarousel/ThreeDCarousel';
import InfiniteCarousal from '../Components/InfiniteCarousal/InfiniteCarousal';
import Footer from '../Components/Footer/Footer';
import Rules from '../Components/Rules/Rules';
import FAQ from '../Components/Faq/Faq';
import logo from '../assets/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import bgMusic from '../assets/bg-audio.mp3';
import Submarine from '../assets/parallex/Submarine.svg';
import coralGolden from '../assets/parallex/shell-gold-01.png';
import coral from '../assets/parallex/shell-sliver.png';
import fish1 from '../assets/parallex/bg/fish2.png';
import fish2 from '../assets/parallex/bg/fish1.png';
import nemo from '../assets/parallex/bg/nemo.png';
import PlanPagenew from './PlanPagenew';
import staticshark from '../assets/ThumbsUpAlphaGIF.gif';
import staticsharkNormal from '../assets/IdleAlphaGIF.gif';
import person1 from '../assets/person1.jpg';
import { Box, Button, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';


export default function HomeDesktop() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [prac, setPrac] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const Playit = async (type: string) => {
    // var audio = await new Audio(bgMusic);
    // console.log(audio);
    // if (type === 'play') {

    //   audio.play();
    // }
    // else {
    //   audio?.pause();
    // }

  }

  function textAnime() {
    var text = document.getElementById('text') as HTMLElement;
    var newDom = '';
    var animationDelay = 6;

    for (let i = 0; i < text.innerText.length; i++) {
      newDom += '<span class="char">' + (text.innerText[i] == ' ' ? '&nbsp;' : text.innerText[i]) + '</span>';
    }

    text.innerHTML = newDom;
    var length = text.children.length;

    for (let i = 0; i < length; i++) {
      // @ts-ignore
      text.children[i].style['animation-delay'] = animationDelay * i + 'ms';
    }

  }
  // useEffect(() => {
  //   Playit('play');
  //   // const element = document.querySelector('.home2-container') as HTMLElement;
  //   // const element1 = document.querySelector('.page1-content1') as HTMLElement;
  //   // const observer = new IntersectionObserver(entries => {
  //   //   element.classList.toggle('animation', entries[0].isIntersecting);
  //   //   element1.classList.toggle('animation', entries[0].isIntersecting);
  //   // });

  //   // observer.observe(element);
  //   // textAnime()


  //   return () => { Playit('stop'); }
  // }, []);
  const [faqs, setFaqs] = useState([
    {
      question: "How is a winner determined?",
      answer:
        "Its based on percentile system. The one who has answered the most amount of questions in the least amount of time gets to be the winner. ",
      open: true
    },
    {
      question: "What if two people answer the same amount of questions in the same time?",
      answer: "We rack upto microseconds of the answer submission. The one with the least amount of time with the same score will win the prize. If even the micro seconds are the same then both of them are eligible for the prize.",
      open: false
    },
    {
      question:
        "When do the prizes get announced?",
      answer: "Once the Quiz is conducted the results are tallied and announced in 24 hours.",
      open: false
    },
    {
      question:
        "How often are the quizzes conducted?",
      answer: "Silver tier quizzes are conducted every week, while the gold tier quizzes are conducted once every month. The diamond tier quizzes are conducted every three months.",
      open: false
    }
  ]);

  const toggleFAQ = (index: number) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  function onPracticeQuiz() {
    setPrac(true);
    setTimeout(() => {
      localStorage.setItem('quizType', 'cricket'); 
      localStorage.setItem('nextPage', 'silverQuiz');
      navigate('/login')
    }, 2000)
    
  }

  return (
    <>
      <div className="deskHome">
        <div className="section mainsection" style={{ overflowX: 'hidden' }}>
          <div className="header">

            <div className="left-side">
              <Button onClick={toggleDrawer(true)} variant='outlined'><i className="fa-solid fa-bars"></i></Button>
              <Link to="/">
                <img src={logo} className="home-logo" alt="Scholar Shark" />
              </Link>
            </div>
            <div className='right-side'>
              <div className='right-side-ite'>
                {/* <Link to="/dashboard">Dashboard</Link> */}
                {/* <Link to="/dashboard">Dashboard</Link> */}
                <img src={person1} alt='person' className='dropdown-img' />
              </div>
            </div>
          </div>
          <div className='page1-content'>
            <img src={prac ? staticshark : staticsharkNormal} alt="shark" className='sharkProfile' />
            <h1 className='page-content-title'>Welcome to</h1>
            <div className="Iam">
              <b>
                <div className="innerIam">
                  FUN<br />
                  EDUCATION<br />
                  ENTERTAINMENT<br />
                  COMPETITION<br />
                  PRIZES WORTH CRORES
                </div>
              </b>

            </div>
            <img className='page-logo' src={logo} alt="logo" />
            <h3>We are an Edutainment platform</h3>
            <button className='carBtn pulse-btn-car' onClick={() =>  onPracticeQuiz()}>Practice Free Quiz</button>
          </div>

          {/* <ThreeDCarousel /> */}
          {/* <AccordionCarousel /> */}
        </div>
        {/* <div className="section section2">
        <div className="home2-container">
          <h1 className="title">
            <span>Wisdom</span>
            <span>is the</span>
            <span>Ultimate</span>
          </h1>

          <h2 className="title">
            <span>key</span>
            <span>for all</span>
            <span>Treasures.</span>


          </h2>

        </div>
        <div className='page1-content1 center'>
          <p id="text">India's biggest knowledge Monetizing platform that celebrates knowledge and rewards the vanquisher. <br/>

            Its time to transform your expertise into profit! Whether it's Finance, Cricket, Food, or Mythology, it's time to COMPETE & CONQUER</p>
        </div>
        <img src={Submarine} className='submar' />
        <div className='goldenCoral-container'>
          <div className='silverCoral-block'>
            <div className="ball">
            </div>
            <img src={coralGolden} alt="coral" className='silverCoral' />
          </div>
        </div>
        <div className='silverCoral-container'>
          <div className='silverCoral-block'>
            <div className="ball">
            </div>
            <img src={coral} alt="coral" className='silverCoral' />
          </div>
        </div>
        <img src={nemo} alt="Fish" className='fishImage' />
        <img src={fish1} alt="Fish" className='fishImage7' />
        <img src={fish2} alt="Fish" className='fishImage10' />
      </div> */}
        <div className="section">
          <ThreeDCarousel />
        </div>
        <div className="section" id="rules">
          <h1 className='section-title'>Rules of Engagement</h1>
          <Rules />
        </div>
        <div className='section'>
          <PlanPagenew hideHeader={true}/>
        </div>
        <div className='section'>
          <h1 className='section-title'>FAQ's</h1>
          <div className="faqs">
            {faqs.map((faq, index) => (
              <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
            ))}
          </div>
        </div>
        <div className='section desktop-only'>
          <h1 className='section-title'>Our Partners</h1>
          <InfiniteCarousal />
          <br />
          <br />
          <Footer />
        </div>
        <div className='section mobile-only'>
          <h1 className='section-title'>Our Partners</h1>
          <InfiniteCarousal />
        </div>
        <div className='section mobile-only'>
          <Footer />
        </div>
      </div>
      <Drawer open={open} onClose={toggleDrawer(false)} className='drawer-menu'>
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
          <List>
            <ListItem key={'Dashboard'} disablePadding>
              <ListItemButton onClick={() => navigate('/dashboard')}>
                <ListItemIcon>
                  {/* {index % 2 === 0 ? <InboxIco /> : <MailIcon />} */}
                  <i className="fa-solid fa-gauge"></i>
                </ListItemIcon>
                <ListItemText primary={'Dashboard'} />
              </ListItemButton>
            </ListItem>
            <ListItem key={'Plans'} disablePadding>
              <ListItemButton onClick={() => navigate('/plans')}>
                <ListItemIcon>
                  {/* {index % 2 === 0 ? <InboxIco /> : <MailIcon />} */}
                  <i className="fa-solid fa-heart"></i>
                </ListItemIcon>
                <ListItemText primary={'Plans'} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  )
}
