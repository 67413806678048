import React, { useContext, useEffect, useState } from "react";
import Lighthouse from "../Components/Lighthouse/Lighthouse";
import { useNavigate, Link } from 'react-router-dom';
import { authenticate } from "../authenticate";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import Layout from "./Layout";
import Userpool from "../Userpool";
import { CognitoUser } from "amazon-cognito-identity-js";
import { PlansContext } from "../Context";
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#13568a',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

function LoginPage() {
    const { plans, setPlans, userInfo, setUserInfo, user, setUser } = useContext(PlansContext);
    const Navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search)

    // /login?type=finance&next=silverPage

    // console.log(queryParams);
    

    // console.log(quizType, nextPage)

    const getCurrentUser = () => {
        const cognitoUser = Userpool.getCurrentUser();

        if (!cognitoUser) {
            
            // navigat("/login");
            return false
        }

        cognitoUser.getSession((err: any, session: any) => {
            if (err) {
                console.log(err);
                return;
            }

            // console.log("Session valid:" + session.isValid());
            // console.log(session);
            // setUser(session.accessToken.payload.username)
            // setUserInfo({
            //     user: session.accessToken.payload.username
            // })

            // cognitoUser.getUserAttributes((err: any, attr: any) => {
            //     if (err) return console.log(err);

            //     callback(attr);
            // })

            // fetchPlans(session.accessToken.payload.username)
            if(localStorage.getItem('nextPage'))
                Navigate(`/${localStorage.getItem('nextPage')}`);
            else {
                Navigate("/home");
            }
        })
    }

    useEffect(() => {
        const quizType = queryParams.get("type");
        const nextPage = queryParams.get("next");

        if(queryParams.size > 0) {
            localStorage.setItem('quizType', quizType  || 'cricket');
            localStorage.setItem('nextPage', nextPage || 'plans');
        }
        getCurrentUser()
    }, [])

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [passwordErr, setPasswordErr] = useState('');
    const [loginErr, setLoginErr] = useState('');
    const [open, setOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleClose = () => {
        setOpen(false);
        Navigate('/verify');
    }
    const [openother, setOpenother] = useState(false);
    const handleCloseOther = () => {
        setOpenother(false);
        Navigate('/register');
    }

    const formInputChange = (formField: string, value: string) => {
        if (formField === "email") {
            setEmail(value);
        }
        if (formField === "password") {
            setPassword(value);
        }
    };

    console.log(userInfo); 

    const validation = () => {
        return new Promise((resolve, reject) => {
            if (email === '' && password === '') {
                setEmailErr("Email is Required");
                setPasswordErr("Password is required")
                resolve({ email: "Email is Required", password: "Password is required" });
            }
            else if (email === '') {
                setEmailErr("Email is Required")
                resolve({ email: "Email is Required", password: "" });
            }
            else if (password === '') {
                setPasswordErr("Password is required")
                resolve({ email: "", password: "Password is required" });
            }
            else if (password.length < 6) {
                setPasswordErr("must be 6 character")
                resolve({ email: "", password: "must be 6 character" });
            }
            else {
                resolve({ email: "", password: "" });
            }
        });
    };

    const handleClick = () => {
        // setUserInfo('manish rane');
        setEmailErr("");
        setPasswordErr("");
        validation()
            .then((res: any) => {
                if (res.email === '' && res.password === '') {
                    authenticate(email, password)
                        .then((data:any) => {
                            console.log(data.idToken.payload);

                            console.log(data)
                           
                            localStorage.setItem("username", data.idToken.payload.name);
                            localStorage.setItem("email", data.idToken.payload.email);
                            localStorage.setItem("phone_number", data.idToken.payload.phone_number);
                            setLoginErr(''); 
                            // localStorage.setItem("user", )
                            
                            if(localStorage.getItem('nextPage'))
                                Navigate(`/${localStorage.getItem('nextPage')}`);
                            else {
                                Navigate("/home");
                            }
                        }, (err) => {
                            console.log(err.code);
                            setLoginErr(err.message);
                            setUser(false);
                            if(err.code === 'UserNotConfirmedException') {
                                setOpen(true);
                            }
                        })
                        .catch(err => console.log(err.code))
                }
            }, err => console.log(err))
            .catch(err => console.log(err));
    }

    


    return (
        <Layout>
            <div className='panel register-page'>
                <div className='panel-left'>
                    <h2>Welcome Shark &#128075;</h2>
                    <p>Welcome to Scholar shark, Unlesh your knowledge Where learning takes a dive </p>

                    <div className="register-form form">
                        <div className="formfield">
                            <TextField
                                value={email}
                                onChange={(e) => formInputChange("email", e.target.value)}
                                label="Email"
                                helperText={emailErr}
                                className="textfield"
                                fullWidth
                                variant="filled"
                                margin="dense"
                            />
                        </div>
                        <div className='formfield'>
                            <TextField
                                value={password}
                                onChange={(e) => { formInputChange("password", e.target.value) }}
                                type={`${showPassword ? 'text' : 'password'}`}
                                label="Password"
                                helperText={passwordErr}
                                className="textfield"
                                fullWidth
                                variant="filled"
                                margin="dense"
                            />
                            <i className={`fa-solid ${showPassword ? 'fa-eye-slash' : 'fa-eye'} showPassword`} onClick={() => setShowPassword(!showPassword)}></i>
                        </div>
                        <Typography variant="body1">{loginErr}</Typography>
                        <div className='formfield'>
                            <Button type='submit' fullWidth variant='contained' onClick={handleClick}>Login</Button>
                        </div>
                        <Link to='/Register' className="linkDisplay">If you are a not registered member click here</Link>
                        
                    </div>
                </div>
                <div className='panel-right'>
                    <h1>Unleash your <br/> knowledge</h1>
                    <p>Where Learning Takes a Dive!</p>
                </div>

            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Please complete the verification.
                    </Typography>
                    <Button variant='contained'  sx={{ mt: 2 }} color='warning' onClick={() => handleClose()}>Ok</Button>
                </Box>
            </Modal>

            <Modal
                open={openother}
                onClose={handleCloseOther}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        User not registered. Please register yourself to enjoy the challenge.
                    </Typography>
                    <Button variant='contained'  sx={{ mt: 2 }} color='warning' onClick={() => handleCloseOther()}>Ok</Button>
                </Box>
            </Modal>
        </Layout>
    )
}

export default LoginPage;
