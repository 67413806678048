import logo from '../assets/logo.png';
import sharkVideo from '../assets/IdleAlphaWEBM.webm';
import { ReactTyped } from "react-typed";
import Card from '../Components/Card/Card';
import Plancard from '../Components/PlanCard/PlanCard';
import person1 from '../assets/person1.jpg';
import goldbg from '../assets/goldbg.png';
import dbg from '../assets/diamondbg.png';
import silverbg from '../assets/silverbg.png';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useContext, useEffect, useState } from 'react';
import { PlansContext } from '../Context';
import staticshark from '../assets/IdleAlphaGIF.gif';
import Userpool from '../Userpool';
import axios from 'axios';
import HomePage from './HomePage';
import Mascot from '../Components/Mascot/Mascot';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import diamond from '../assets/parallex/clams01.png'
import Leaderboard from '../Components/Leaderboard/Leaderboard';
import cricket from '../assets/mascot/cricket.png';
import travel from '../assets/mascot/traveler.png';
import foodie from '../assets/mascot/foodie.png';
import finance from '../assets/mascot/finance.gif';
import history from '../assets/mascot/king.png';
import mythology from '../assets/mascot/sage.png';
import { Link, useNavigate } from 'react-router-dom';
import { authenticate } from '../authenticate';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Box, Button, Modal, Typography } from '@mui/material';
import usePersistState from '../usePersistState';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#13568a',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function DashboardPage() {
    const { plans, info, userInfo, setUserInfo, setUserDetails } = useContext(PlansContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [plan, setPlan] = useState('');
    const [name, setName] = useState('');
    const [profile, setProfile] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [passwordErr, setPasswordErr] = useState('');
    const [user, setUser] = useState('');
    const [silverQuiz, setSilverQuiz] = useState<string>();
    const [isSilverQuizSelected, setIsSilverQuizSelected] = useState<boolean>();
    const [isSilverQuizDBUpdated, setIsSilverQuizDBUpdated] = useState<boolean>();
    const [goldQuiz, setGoldQuiz] = useState<string>();
    const [isGoldQuizSelected, setIsGoldQuizSelected] = useState<boolean>();
    const [isGoldQuizDBUpdated, setIsGoldQuizDBUpdated] = useState<boolean>();
    const [purchasedPlans, setPurchasedPlans] = useState<any[] | undefined>();
    const purchasedPlan: any = [];
    const mySidenav = document.getElementById("mySidenav") as HTMLElement;
    const backdrop = document.getElementById('backdrop') as HTMLElement;
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
        // navigate('/login');
        if (isSilverQuizSelected || isGoldQuizSelected) {
            updatePlanInDB(silverQuiz, goldQuiz);
        }
    }
    const [openOther, setOpenOther] = useState(false);
    const handleCloseOther = () => {
        setOpenOther(false);
        // navigate('/login');
        if (isSilverQuizSelected || isGoldQuizSelected) {
            updatePlanInDB(silverQuiz, goldQuiz);
        }
    }
    const [planpurchased, setPlanpurchased] = useState();
    const [userData, setUserData] = usePersistState('userData', {});

    const onQuizSelected = (planType: string, quizType: string) => {
        console.log(planType)
        if (planType === 'silver') {
            setIsSilverQuizSelected(true);
            setSilverQuiz(quizType);
            setOpenOther(true);
        } else if (planType === 'gold') {
            setIsGoldQuizSelected(true);
            setGoldQuiz(quizType);
            setOpen(true);
        }

        console.log(goldQuiz);
        console.log(silverQuiz);
        console.log(isGoldQuizSelected);
        console.log(isSilverQuizSelected)
        // console.log(quizType);
        // setIsGoldQuizSelected(true);
        // setGoldQuiz(quizType);
        // console.log(goldQuiz);
        // console.log(isGoldQuizSelected);

    }

    // useEffect(() => {
    //     console.log('data updated');
    //     if(isSilverQuizSelected || isGoldQuizSelected) {
    //         updatePlanInDB(silverQuiz, goldQuiz);
    //     }

    //     // if()
    // }, [silverQuiz, goldQuiz])

    const options = {
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            800: {
                items: 1,
            },
            1000: {
                items: 1,
            }
        },
        nav: true,
        dots: false,
        autoplay: false
    }

    const optionsSilver = {
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            800: {
                items: 1,
            },
            1000: {
                items: 1,
            }
        },
        nav: true,
        dots: false,
        autoplay: true
    }

    const options2 = {
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 2,
            },
            600: {
                items: 2,
            },
            700: {
                items: 2,
            },
            800: {
                items: 2,
            },
            1000: {
                items: 2,
            }
        },
        nav: false,
        dots: true,
        autoplay: true
    }

    const optionsGold = {
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            800: {
                items: 1,
            },
            1000: {
                items: 1,
            }
        },
        nav: true,
        dots: false,
        autoplay: true
    }


    const optionsDiamond = {
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            800: {
                items: 1,
            },
            1000: {
                items: 1,
            }
        },
        nav: false,
        dots: false,
        autoplay: false
    }


    const getCurrentUser = (callback: any) => {
        const cognitoUser = Userpool.getCurrentUser();

        if (!cognitoUser) {
            
            navigate("/login");
            return false
        }

        cognitoUser.getSession((err: any, session: any) => {
            if (err) {
                console.log(err);
                return;
            }

            // console.log("Session valid:" + session.isValid());
            // console.log(session);
            setUser(session.accessToken.payload.username)
            // setUserInfo({
            //     user: session.accessToken.payload.username
            // })

            cognitoUser.getUserAttributes((err, attr) => {
                if (err) return console.log(err);

                callback(attr);
            })

            fetchPlans(session.accessToken.payload.username)
        })
    }

    const fetchPlans = (userId: string) => {
        console.log(userId);

        // let config = {

        //     method: "get",
        //     maxBosyLength: Infinity,
        //     url: `https://iy5ispsidmfhpzojalaofamqiq0nerep.lambda-url.ap-south-1.on.aws/getplans/${userId}`,
        //     headers: {
        //         'Content-Type': 'application/json'
        //     }
        // }

        axios.get(`https://iy5ispsidmfhpzojalaofamqiq0nerep.lambda-url.ap-south-1.on.aws/getplans/${userId}`)
            .then((res: any) => {
                console.log(res.data[0])
                setPlanpurchased(res.data[0])
                // handleRazorpayScreen(response.data.amount)
                res.data.map((d: any) => purchasedPlan.push(d.planType));
                console.log(purchasedPlan);
                setPurchasedPlans(purchasedPlan)
                // console.log(purchasedPlans)
                // console.log(planpurchased)
                // setUserDetails(planpurchased)

                setIsGoldQuizDBUpdated(res.data[0].value2 === 'history' || res.data[0].value2 === 'mythology' || res.data[0].value2 === 'finance')
                setIsSilverQuizDBUpdated(res.data[0].value1 === 'cricket' || res.data[0].value1 === 'travel' || res.data[0].value1 === 'food')
                setSilverQuiz(res.data[0].value1)
                setGoldQuiz(res.data[0].value2)
                console.log('Gold plan', isGoldQuizDBUpdated)
                console.log('Silver plan', isSilverQuizDBUpdated)
                console.log(userData)
            })
            .catch((error: any) => {
                console.log("error at", error)
            })

        // let config = {
        //     method: "post",
        //     maxBosyLength: Infinity,
        //     url: "https://iy5ispsidmfhpzojalaofamqiq0nerep.lambda-url.ap-south-1.on.aws/updateuserdetails",
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'x-api-key': '3Cp28MeCN19CEJYoox59pamRkuFcj72l3RFdxAYa'
        //     },
        // }

        // axios.request(config)
        //     .then(response => {
        //         console.log(JSON.stringify(response.data))
        //         // handleRazorpayScreen(response.data.amount)
        //     })
        //     .catch(error => {
        //         console.log("error at", error)
        //     })

        // console.log(purchasedPlans)

        // setPurchasedPlans([...purchasedPlans, purchasedPlan])
    }

    useEffect(() => {

        getCurrentUser((attr: any) => {
            for (let i = 0; i < attr.length; i++) {
                console.log(attr[i].Name)
                if (attr[i].Name === "email") {
                    // console.log(attr[i].)
                    setEmail(attr[i].Value);
                }

                if (attr[i].Name === "phone_number") {
                    setPassword(attr[i].Value);
                }

                if (attr[i].Name === "custom:plan") {
                    setPlan(attr[i].Value);
                }

                if (attr[i].Name === "picture") {
                    setProfile(attr[i].Value);
                }

                if (attr[i].Name === "name") {
                    setName(attr[i].Value);
                }
            }
        })
    }, [])

    function openNav() {
        // console.log(mySidenav);
        //@ts-ignore
        document.getElementById("mySidenav").style.width = window.innerWidth <= 991 ? "70%" : "20%" //opens side navbar by 70 percent
        backdrop.style.display = "block" //displays overlay
        // dashboard.classList.add('open-nav');
        // dashboard.style.marginLeft = '20%'
    }

    function closeNav() {
        //@ts-ignore
        document.getElementById("mySidenav").style.width = "0%"
        // dashboard.classList.remove('open-nav');
        backdrop.style.display = "none"
        // dashboard.style.width = '0%'
        // dashboard.style.marginLeft = '0%'
    }

    function logout() {
        const user = new CognitoUser({
            Username: email,
            Pool: Userpool,
        });

        console.log(user.signOut());
        user.signOut();
        localStorage.removeItem('nextPage');
        closeNav();
        navigate('/')
    }

    const updatePlanInDB = (silverQuizType: any, goldQuizType: any) => {

        console.log(planpurchased)
        console.log(silverQuizType);
        console.log(goldQuizType)

        // let data = JSON.stringify({
        //     ...(planpurchased as object) ,
        //     value1: silverQuizType,
        //     value2: 'updateFormDB',
        //     value3: 'value1',
        //     value4: 'value1',
        //     value5: 'value1'
        // })


        let data = JSON.stringify({
            //@ts-ignore
            userId: planpurchased.userId,
            //@ts-ignore
            planid: planpurchased.planid,
            //@ts-ignore
            planType: planpurchased.planType,
            //@ts-ignore
            isActive: planpurchased.isActive,
            //@ts-ignore
            from: planpurchased.from,
            //@ts-ignore
            to: planpurchased.to,
            //@ts-ignore
            isUpgraded: planpurchased.isUpgraded,
            //@ts-ignore
            createdOn: planpurchased.createdOn,
            //@ts-ignore
            updatedOn: planpurchased.updatedOn,
            //@ts-ignore
            value1: String(silverQuizType),
            //@ts-ignore
            value2: String(goldQuizType),
            //@ts-ignore
            value3: planpurchased.value3,
            //@ts-ignore
            value4: planpurchased.value4,
            //@ts-ignore
            value5: planpurchased.value5
        })

        console.log(planpurchased);

        let config = {
            method: "post",
            maxBosyLength: Infinity,
            url: "https://iy5ispsidmfhpzojalaofamqiq0nerep.lambda-url.ap-south-1.on.aws/updateplan",
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': '3Cp28MeCN19CEJYoox59pamRkuFcj72l3RFdxAYa'
            },
            data: data
        }

        axios.request(config)
            .then(response => {
                console.log(JSON.stringify(response.data))
                // handleRazorpayScreen(response.data.amount)
            })
            .catch(error => {
                console.log("error at", error)
            })
    }

    function onQuizPractice (practice: string){
        localStorage.setItem("quizType", practice);
        navigate('/silverQuiz')
    }

    return (
        <>
            <div className="dashboardPage" id="dashboard">
                <div className="header">
                    <div className="left-side">
                        {/* <span onClick={() => openNav()} className="mobile-nav-open-icon">&#9776;</span> */}
                        <Link to="/home">
                            <img src={logo} className="home-logo" alt="Scholar Shark" />
                        </Link>

                    </div>
                    <div className='right-side'>
                        <div>
                            <img onClick={() => openNav()} src={profile ? profile : person1} alt='person' className='dropdown-img' />
                        </div>
                    </div>
                </div>
                <div className='dashboard-user'>
                    Welcome, {name}
                </div>
                <div className="info-container">
                    {/* <div> */}
                    <img src={staticshark} alt="shark" className='sharkProfile' />
                    {/* <h5>Welcome Akshay</h5>
                    </div> */}

                    <div className='dashboard-card-container dashboard-card-container-desk'>
                        <div className='dashboard-card plan-bg'>
                            <div className='dashboard-inner'>
                                <h3>Current Plan</h3>
                                <h1>{//@ts-ignore 
                                    purchasedPlans?.length > 1 ? purchasedPlans[0] : purchasedPlans
                                } Fin
                                </h1>
                            </div>
                        </div>
                        <div className='dashboard-card quiz-bg'>
                            <div className='dashboard-inner'>
                                <h3>Selected Quiz</h3>
                                <h1><h1>{`${silverQuiz}, ${goldQuiz}`}</h1></h1>
                            </div>
                        </div>
                        <div className='dashboard-card part-bg'>
                            <div className='dashboard-inner'>
                                <h3>Participated in Practice Quiz </h3>
                                <h1>{
                                    //@ts-ignore
                                    isNaN(planpurchased?.value3) ? 0 : planpurchased?.value3 
                                }</h1>
                            </div>
                        </div>
                        <div className='dashboard-card next-bg'>
                            <div className='dashboard-inner'>
                                <h3>Next Quiz on</h3>
                                <h1>20th Oct</h1>
                            </div>
                        </div>
                        <div className='dashboard-card total-bg'>
                            <div className='dashboard-inner'>
                                <h3>Grand total participant</h3>
                                <h1>40000</h1>
                            </div>
                        </div>
                        <div className='dashboard-card prize-bg'>
                            <div className='dashboard-inner'>
                                <h3>Distributed prizzes worth</h3>
                                <h1>5 Cr.</h1>
                            </div>
                        </div>
                    </div>
                    <div className='dashboard-card-container dashboard-card-container-mobile'>
                        <OwlCarousel className='owl-theme' loop margin={20} {...options2}>
                            <div className='dashboard-card item plan-bg'>
                                <div className='dashboard-inner'>
                                    <h3>Current Plan</h3>
                                    <h1>{//@ts-ignore 
                                        purchasedPlans?.length > 1 ? purchasedPlans[0] : purchasedPlans
                                    } Fin</h1>
                                </div>
                            </div>
                            <div className='dashboard-card item quiz-bg'>
                                <div className='dashboard-inner'>
                                    <h3>Selected Quiz</h3>
                                    <h1>{`${silverQuiz}, ${goldQuiz}`}</h1>
                                </div>
                            </div>
                            <div className='dashboard-card item part-bg'>
                                <div className='dashboard-inner'>
                                    <h3>Participated in Practice Quiz </h3>
                                    <h1>{
                                        //@ts-ignore
                                        isNaN(planpurchased?.value3) ? 0 : planpurchased?.value3 
                                    }</h1>
                                </div>
                            </div>
                            <div className='dashboard-card item next-bg'>
                                <div className='dashboard-inner'>
                                    <h3>Next Quiz on</h3>
                                    <h1>20th Oct</h1>
                                </div>
                            </div>
                            <div className='dashboard-card item total-bg'>
                                <div className='dashboard-inner'>
                                    <h3>Grand total participant</h3>
                                    <h1>40000</h1>
                                </div>
                            </div>
                            <div className='dashboard-card item prize-bg'>
                                <div className='dashboard-inner'>
                                    <h3>Distributed prizzes worth</h3>
                                    <h1>5 Cr.</h1>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
                {
                    planpurchased &&
                    <div className='quiz-dashboard-container'>
                        <h1>Select Quiz</h1>
                        <div className='dashboard-plans-container'>
                            {
                                //@ts-ignore
                                (planpurchased?.planType === 'silver' || planpurchased?.planType === 'diamond') &&
                                !isSilverQuizDBUpdated &&
                                <div>
                                    <OwlCarousel className='owl-theme' loop margin={20} {...optionsSilver}>

                                        {/* 1 */}
                                        <div className="review item">
                                            <h3>Mr. AshFin</h3>
                                            <div className="review-img">
                                                <img src={cricket} alt="" />
                                            </div>
                                            <button disabled={isSilverQuizSelected} className='carBtn pulse-btn-car' onClick={() => onQuizSelected('silver', 'cricket')}>Select</button>
                                        </div>

                                        {/* 2 */}
                                        <div className="review item">
                                            <h3>Mr. Travel Fin</h3>
                                            <div className="review-img">
                                                <img src={travel} alt="" />
                                            </div>
                                            <button disabled={isSilverQuizSelected} className='carBtn pulse-btn-car' onClick={() => onQuizSelected('silver', 'travel')}>Select</button>
                                        </div>


                                        {/* 3 */}
                                        <div className="review item">
                                            <h3>Mr. Foodie Fin</h3>
                                            <div className="review-img">
                                                <img src={foodie} alt="" />
                                            </div>
                                            <button disabled={isSilverQuizSelected} className='carBtn pulse-btn-car' onClick={() => onQuizSelected('silver', 'food')}>Select</button>
                                        </div>

                                    </OwlCarousel>
                                </div>
                            }

                            {
                                //@ts-ignore
                                (planpurchased?.planType === 'silver' || planpurchased?.planType === 'diamond') &&
                                isSilverQuizDBUpdated &&
                                silverQuiz === 'cricket' &&
                                (
                                    <div className="review item">
                                        <h3>Mr. AshFin</h3>
                                        <div className="review-img">
                                            <img src={cricket} alt="" />
                                        </div>
                                        <button className='carBtn pulse-btn-car' onClick={() => onQuizPractice('cricket')}>Practice</button>
                                    </div>
                                )
                            }

{
                                //@ts-ignore
                                (planpurchased?.planType === 'silver' || planpurchased?.planType === 'diamond') &&
                                isSilverQuizDBUpdated &&
                                silverQuiz === 'travel' &&
                                (
                                    <div className="review item">
                                        <h3>Mr. Travel Fin</h3>
                                        <div className="review-img">
                                            <img src={travel} alt="" />
                                        </div>
                                        <button className='carBtn pulse-btn-car' onClick={() => onQuizPractice('travel')}>Practice</button>
                                    </div>
                                )
                            }

                            {
                                //@ts-ignore
                                (planpurchased?.planType === 'silver' || planpurchased?.planType === 'diamond') &&
                                isSilverQuizDBUpdated &&
                                silverQuiz === 'food' &&
                                (
                                    <div className="review item">
                                        <h3>Mr. Foodie Fin</h3>
                                        <div className="review-img">
                                            <img src={foodie} alt="" />
                                        </div>
                                        <button className='carBtn pulse-btn-car' onClick={() => onQuizPractice('food')}>Practice</button>
                                    </div>
                                )
                            }

                            {
                                //@ts-ignore
                                (planpurchased?.planType === 'gold' || planpurchased?.planType === 'diamond') &&
                                !isGoldQuizDBUpdated &&
                                <div>
                                    <OwlCarousel className='owl-theme' loop margin={20} {...optionsGold}>

                                        {/* 1 */}
                                        <div className="review item">
                                            <h3>Mr. History Fin</h3>
                                            <div className="review-img">
                                                <img src={history} alt="" />
                                            </div>
                                            <button disabled={isGoldQuizSelected} className='carBtn pulse-btn-car' onClick={() => onQuizSelected('gold', 'history')}>Select</button>
                                        </div>

                                        {/* 2 */}
                                        <div className="review item">
                                            <h3>Mr. Mythology Fin</h3>
                                            <div className="review-img">
                                                <img src={mythology} alt="" />
                                            </div>
                                            <button disabled={isGoldQuizSelected} className='carBtn pulse-btn-car' onClick={() => onQuizSelected('gold', 'mythology')}>Select</button>
                                        </div>

                                        {/* 2 */}
                                        <div className="review item">
                                            <h3>Mr. Finance Fin</h3>
                                            <div className="review-img">
                                                <img src={finance} alt="" />
                                            </div>
                                            <button disabled={isGoldQuizSelected} className='carBtn pulse-btn-car' onClick={() => onQuizSelected('gold', 'finance')}>Select</button>
                                        </div>

                                    </OwlCarousel>
                                </div>
                            }

                            {
                                //@ts-ignore
                                (planpurchased?.planType === 'gold' || planpurchased?.planType === 'diamond') &&
                                isGoldQuizDBUpdated &&
                                goldQuiz === 'history' &&
                                (
                                    <div className="review item">
                                        <h3>Mr. History Fin</h3>
                                        <div className="review-img">
                                            <img src={history} alt="" />
                                        </div>
                                        <button className='carBtn pulse-btn-car' onClick={() => onQuizPractice('history')}>Practice</button>
                                    </div>
                                )
                            }

                            {
                                //@ts-ignore
                                (planpurchased?.planType === 'gold' || planpurchased?.planType === 'diamond') &&
                                isGoldQuizDBUpdated &&
                                goldQuiz === 'mythology' &&
                                (
                                    <div className="review item">
                                        <h3>Mr. Mythology Fin</h3>
                                        <div className="review-img">
                                            <img src={mythology} alt="" />
                                        </div>
                                        <button className='carBtn pulse-btn-car' onClick={() => onQuizPractice('mythology')}>Practice</button>
                                    </div>
                                )
                            }

{
                                //@ts-ignore
                                (planpurchased?.planType === 'gold' || planpurchased?.planType === 'diamond') &&
                                isGoldQuizDBUpdated &&
                                goldQuiz === 'finance' &&
                                (
                                    <div className="review item">
                                        <h3>Mr. Finance Fin</h3>
                                        <div className="review-img">
                                            <img src={finance} alt="" />
                                        </div>
                                        <button className='carBtn pulse-btn-car' onClick={() => onQuizPractice('finance')}>Practice</button>
                                    </div>
                                )
                            }
                            
                            {
                                //@ts-ignore
                                planpurchased?.planType === 'diamond' &&
                                <div className='review item'>
                                
                                    <div className="">
                                        <h3>Mr. Diamond Fin </h3>
                                        <div className="review-img">
                                            <img src={finance} alt="" />
                                        </div>
                                        <h4 style={{marginTop: "20px"}}>You can choose quiz type after 28th Nov 2024</h4>
                                        {/* <button className='carBtn pulse-btn-car' onClick={() => onQuizPractice('finance')}>Practice</button> */}
                                    </div>
                                </div>
                            }
                            
                        </div>

                    </div>
                }

                {/* <div>
                    <div>

                    </div>
                    <div>

                    </div>
                </div> */}

                {/* <div>
                    <h4>At scholar shark, we believe knowledge of any form should be heavily rewarded. So swim with us, take our quiz and win exciting prizes</h4>
                </div> */}
                <div className='d-flex'>
                    <div className='rank-panel'>
                        <div className='you-rank'>
                            <h3>Your Ranking #</h3>
                            <h1>9900</h1>
                        </div>
                        {/* <div className='you-rank'>
                            <h3>Top Scorer</h3>
                            <h1>Jyoti</h1>
                            <img src={person1} alt="person" className='top-score' />
                        </div> */}
                    </div>
                    <div className='leaderboard-carousal'>
                        <OwlCarousel className='owl-theme' loop margin={20} {...options}>

                            {/* 1 */}
                            <div className="review item">
                                <Leaderboard type={"Silver"} />
                            </div>

                            {/* 2 */}
                            <div className="review item">
                                <Leaderboard type={"Gold"} />
                            </div>


                            {/* 3 */}
                            <div className="review item">
                                <Leaderboard type={"Diamond"} />
                            </div>

                        </OwlCarousel>
                    </div>
                    <div className='diamond-tier-adv'>
                        <h3>What you are missing on</h3>
                        <img src={diamond} alt="diamond" />
                        <h2>Prizzes worth 15 CR.</h2>
                        <button className='carBtn pulse-btn-car' onClick={() => navigate('/plans')}>Challenge Now</button>
                    </div>
                </div>

                <div className="leaderboard"></div>
            </div>
            <div id="mySidenav" className="sidenav-container">

                <span className="drawer-close-button">
                    <a href="javascript:void(0)" className="closebtn" onClick={() => closeNav()}>&times;</a>
                </span>

                <h2>My Profile</h2>
                <img src={profile} alt='profile' className='profileImage' />
                <h3>{name}</h3>
                <p><i className="fa-solid fa-envelope"></i> {email}</p>

                {/* <a href="#home" onClick={() => closeNav()} id="home-link">Home</a>
                <a href="#about" onClick={() => closeNav()} id="about-link">About Me</a>
                <a href="#works" onClick={() => closeNav()} id="works-link">My WorkS</a>
                <a href="#contact" onClick={() => closeNav()} id="contact-link">Contact Me</a> */}
                <div style={{ marginTop: '20px' }}>
                    <Button variant='text' className='logout' onClick={() => alert()}>
                        <i className="fa-solid fa-key menuBtn"></i>
                        Password
                    </Button>
                    <Button variant='text' className='logout' onClick={() => logout()}>
                        <i className="fa-solid fa-right-from-bracket menuBtn"></i>
                        Logout
                    </Button>
                </div>

                <div>
                    <h3>Your Activity</h3>
                    <ul className='dashboard-plan-list'>
                        {purchasedPlans?.map(plan => (
                            <li className='dashboard-plan'><i className={`fa-solid fa-trophy icon ${plan}-icon`}></i>{plan}</li>
                        ))}
                    </ul>

                </div>

            </div>


            <div className="backdrop-container" onClick={() => closeNav()} id="backdrop"></div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {`Are you sure you want to select ${goldQuiz} from Gold Fin`}
                    </Typography>
                    <Button variant='contained' sx={{ mt: 2 }} color='warning' onClick={() => handleClose()}>Ok</Button>
                </Box>
            </Modal>
            <Modal
                open={openOther}
                onClose={handleCloseOther}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {`Are you sure you want to select ${silverQuiz} from Silver Fin`}
                    </Typography>
                    <Button variant='contained' sx={{ mt: 2 }} color='warning' onClick={() => handleCloseOther()}>Ok</Button>
                </Box>
            </Modal>

        </>
    )
}

export default DashboardPage;
