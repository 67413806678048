import React, { useEffect, useState } from 'react'
import Parallex from '../Components/Parallex/Parallex'
import Loader from '../Components/Loader/Loader';

export default function Home() {

  const [isLoading, setIsLoading] = useState(true);
    
      // useEffect(() => {
      //   // Simulate an API call
      //   setTimeout(() => {
      //     setIsLoading(false);
      //   }, 1000);
      // }, []);
    
      // if (isLoading) {
      //   return <Loader />;
      // }

  return (
    <Parallex />
  )
}
