import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import travelLogo from '../assets/mascot/finance.gif';

const FinancePage = () => {
	const Navigate = useNavigate();

	function carQuizBtn () {
		localStorage.setItem('quizType', 'finance');
		localStorage.setItem('nextPage', 'silverQuiz');
		Navigate('/login');
	}

	function carBtn () {
		localStorage.setItem('quizType', 'finance');
		localStorage.setItem('nextPage', 'plans');
		Navigate('/login');
	}
	return (
		<div className='quizPanel blogPanel'>
			<div className='quizPanel-left'>
				<div className='quizPanel-section' style={{ width: "100%", height: "100%" }}>
					<div className='quizPanel-logo' style={{ marginLeft: 0 }}>
						<img src={logo} alt="logo" />
					</div>
					<div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", flex: 1 }}>
						<img src={travelLogo} style={{ height: "350px" }} />
					</div>
					<div>
						<h3  style={{textAlign: "center", marginTop: "20px"}}>Quiz Date: 3rd Nov 2024</h3>
						<div className='carBtn-grp'  style={{marginBottom: "20px"}}>
							<button className='carBtn pulse-btn-car' onClick={carBtn}>Challenge Now</button>
                			<button className='carQuizBtn pulse-btn-car' onClick={carQuizBtn}>Practice Quiz</button>
						</div>
					</div>
				</div>
			</div>
			<div className='quizPanel-right'>
				<div>
					<h2 style={{ marginBottom: '20px', textAlign: "center", textDecoration: "underline" }}>Finance</h2>
					<h3 style={{ marginBottom: '10px' }}>Here are some engaging topics you can practice and prepare for the Finance quiz</h3>
					<ol>
						<li>
							<b>Banking System in India</b>
							<ul>
								<li>
									Types of banks (Public, Private, Cooperative, etc.)
								</li>
								<li>
									Functions of the Reserve Bank of India (RBI)
								</li>
								<li>
									Nationalization of banks and reforms
								</li>
								<li>
									Important banking terms (CRR, SLR, Repo rate, etc.)
								</li>
							</ul>
						</li>

						<li>
							<b>Indian Financial Markets</b>
							<ul>
								<li>
									Stock markets (BSE, NSE)
								</li>
								<li>
									SEBI and its functions
								</li>
								<li>
									Major stock indices (Sensex, Nifty)
								</li>
								<li>
									Types of investments (Equity, Debt, Mutual Funds)
								</li>
							</ul>
						</li>

						<li>
							<b>Indian Taxation System</b>
							<ul>
								<li>
									Income Tax structure (slabs, exemptions).
								</li>
								<li>
									Goods and Services Tax (GST).
								</li>
								<li>
									Direct and indirect taxes
								</li>
								<li>
									Tax reforms in India
								</li>
							</ul>
						</li>

						<li>
							<b>Government Financial Schemes</b>
							<ul>
								<li>
									Jan Dhan Yojana, Ujjwala Yojana, PM-KISAN
								</li>
								<li>
									Microfinance and financial inclusion schemes
								</li>
								<li>
									Mudra Loan and other MSME initiatives
								</li>
								<li>
									Atal Pension Yojana, Sukanya Samriddhi Yojana
								</li>
							</ul>
						</li>

						<li>
							<b>Budget and Economic Planning</b>
							<ul>
								<li>
									Structure and importance of the Union Budget.
								</li>
								<li>
									Key financial terms (deficit, fiscal policy, revenue).
								</li>

								<li>
									Five-Year Plans and NITI Aayog
								</li>
								<li>
									Recent budget highlights and key reforms
								</li>
							</ul>
						</li>

						<li>
							<b>Indian Currency and Foreign Exchange</b>
							<ul>
								<li>
									History of Indian Rupee.
								</li>
								<li>
									Role of forex reserves.

								</li>
								<li>
									Impact of exchange rate fluctuations.
								</li>
								<li>
									FDI and FII in India.
								</li>
							</ul>
						</li>

						<li>
							<b>Insurance Sector in India</b>
							<ul>
								<li>
									Types of insurance (life, health, general).

								</li>
								<li>
									Role of IRDAI (Insurance Regulatory and Development Authority of India).
								</li>
								<li>
									Popular insurance schemes (Ayushman Bharat, PMJJBY).
								</li>
							</ul>
						</li>

						<li>
							<b>History of Indian Financial Reforms</b>
							<ul>
								<li>
									1991 Economic Liberalization.

								</li>
								<li>
									Demonetization (2016).
								</li>
								<li>
									GST implementation.
								</li>
								<li>
									Insolvency and Bankruptcy Code (IBC).
								</li>
							</ul>
						</li>

						<li>
							<b>Digital and Fintech Revolution in India</b>
							<ul>
								<li>
									UPI, mobile banking, and digital wallets.
								</li>
								<li>
									Impact of fintech on traditional banking.
								</li>
								<li>
									Cryptocurrency in India (policies and regulations).
								</li>
							</ul>
						</li>

						<li>
							<b>Financial Literacy and Education</b>
							<ul>
								<li>
									Importance of financial planning and budgeting.
								</li>
								<li>
									Personal finance management.
								</li>
								<li>
									Investment options for individuals (PPF, NSC, etc.).
								</li>
								<li>
									Role of credit scores and credit management.
								</li>
							</ul>
						</li>

					</ol>
				</div>
			</div>
			<Link to="/home" className='close'>
				X
			</Link>
		</div>
	)
}

export default FinancePage
