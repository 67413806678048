import React, { useContext, useState, useMemo } from "react";
import Lighthouse from "../Components/Lighthouse/Lighthouse";
import { Link } from "react-router-dom";
import { PlansContext } from "../Context";
import { useNavigate } from 'react-router-dom';
import { CognitoUserAttribute, CognitoUser } from 'amazon-cognito-identity-js';
import axios from 'axios';
import Userpool from "../Userpool";
import { Button, TextField, Select, MenuItem } from '@mui/material';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Layout from "./Layout";

// const useStyles = makeStyles((theme: any) => ({
//   input: {
//     background: "rgb(232, 241, 250)"
//   }
// }));

function VerifyPage() {
    const { plans, setInfo } = useContext(PlansContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [passwordErr, setPasswordErr] = useState('');
    const [phoneErr, setPhoneErr] = useState('');
    const [codeErr, setCodeErr] = useState('');
    const [nameErr, setNameErr] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [showCode, setShowCode] = useState(false);
    const navigate = useNavigate();
    const [value, setValue] = useState('')
    const [phoneValue, setPhoneValue] = useState();
    const [verifyCode, setVerifyCode] = useState(false);
    const [checkPassword, setCheckPassword] = useState(true);


    const changeHandler = (value: any) => {
        setValue(value)
    }

    const verifyAccount = (e: any) => {
        e.preventDefault();
        const user = new CognitoUser({
            Username: email,
            Pool: Userpool,
        });
        console.log(user);
        user.confirmRegistration(code, true, (err, data) => {
            if (err) {
                console.log(err);
                // alert("Couldn't verify account");
                // sendEmail(email);
                setShowCode(false);
                navigate('/login');
            } else {
                console.log(data);
                // alert('Account verified successfully');
                // window.location.href = '/login';
                // sendEmail(email);
                setShowCode(false);
                navigate('/login');
            }
        });
    };

    // function sendEmail(email: string) {
    //     let data = JSON.stringify({
    //         email: email,
    //         subject: "Account registration successful!",
    //         textBody: "Congrats"
    //     })

    //     let config = {
    //         method: "post",
    //         maxBosyLength: Infinity,
    //         url: "https://vpoa86v9o6.execute-api.ap-south-1.amazonaws.com/dev/send-email",
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'x-api-key': '3Cp28MeCN19CEJYoox59pamRkuFcj72l3RFdxAYa'
    //         },
    //         data: data
    //     }

    //     axios.request(config)
    //         .then(response => {
    //             console.log(JSON.stringify(response.data))
    //             // handleRazorpayScreen(response.data.amount)
    //         })
    //         .catch(error => {
    //             console.log("error at", error)
    //         })
    // }

    const resendCode = (e: any) => {
        const user = new CognitoUser({
            Username: email,
            Pool: Userpool,
        });
        user.resendConfirmationCode((err, data) => {
            if (err) {
                console.log(err);
                // alert("Couldn't verify account");
                // setShowCode(false);
                // navigate('/login');
            } else {
                console.log(data);
                setShowCode(true);
                setVerifyCode(false);
                // alert('Account verified successfully');
                // window.location.href = '/login';
                // setShowCode(false);
                // navigate('/login');
            }
        })
    }


    const formInputChange = (formField: string, value: string) => {
        if (formField === "email") {
            setEmail(value);
        }
        if (formField === "code") {
            setCode(value);
        }
        if (formField === "name") {
            setName(value);
        }
    };

    const validation = () => {
        return new Promise((resolve, reject) => {
            if (email === '' && password === '') {
                setEmailErr("Email is Required");
                setPasswordErr("Password is required")
                resolve({ email: "Email is Required", password: "Password is required" });
            }
            else if (email === '') {
                setEmailErr("Email is Required")
                resolve({ email: "Email is Required", password: "" });
            }
            else if (password === '') {
                setPasswordErr("Password is required")
                resolve({ email: "", password: "Password is required" });
            }
            else if (password.length < 8) {
                setPasswordErr("must be 6 character")
                resolve({ email: "", password: "must be 6 character" });
            }
            else {
                resolve({ email: "", password: "" });
            }
            reject('')
        });
    };
    
    return (
        <Layout>
            <div className='panel register-page'>
                <div className='panel-left'>
                    <h2>Welcome Shark &#128075;</h2>
                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}

                    <div className="register-form">

                        {
                            showCode &&
                            <div className="form">
                                <div className='formfield'>
                                    <TextField
                                        value={code}
                                        onChange={(e) => { formInputChange("code", e.target.value) }}
                                        type="text"
                                        label="Code"
                                        helperText={codeErr}
                                        variant="filled"
                                        margin="dense"
                                        fullWidth
                                        className="textfield"
                                    />
                                </div>
                                <div className='formfield'>
                                    <Button type='submit' variant='contained' fullWidth onClick={verifyAccount}>Verify Account</Button>
                                </div>
                                
                            </div>
                        }

                        {
                            !showCode && 
                            <div className="form">
                                <div className="formfield">
                                    <TextField
                                        value={email}
                                        onChange={(e) => formInputChange("email", e.target.value)}
                                        label="Email"
                                        helperText={emailErr}
                                        variant="filled"
                                        margin="dense"
                                        fullWidth
                                        className="textfield"
                                    />
                                    <div className='formfield'>
                                        <Button type='submit' variant='contained' fullWidth onClick={resendCode}>Resend</Button>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                    <Link to='/login' className="linkDisplay">If you are register member click here</Link>
                    {/* {
                        <button onClick={handleSummary} className={`register-btn ${(email.length > 0 && phone.length > 0 && name.length > 0) === true ? '' : 'disabled'}`}>
                            Summary
                        </button>

                        // <Link to='/summary' className={`register-btn ${(email.length > 0 && mobileNumber.length > 0 && name.length > 0) === true ? '' : 'disabled'}`}>

                        // </Link>
                    } */}

                </div>
                <div className='panel-right'>
                    <h1>Unleash your <br /> knowledge</h1>
                    <p>Where Learning Takes a Dive!</p>
                </div>
            </div>
        </Layout>
    )
}

export default VerifyPage;